import _ from 'lodash';
import Vue from 'vue';
import { applicationService } from '@/services';
import { GUID_EMPTY } from '@/helpers';
import { v4 as uuidv4 } from 'uuid';
import {
    GET_ALL,
    GET_BY_PORTFOLIO_ID,
    GET_BY_ID,
    GET_NEW,
    ADD,
    UPDATE,
    DELETE,
    CALCULATE_TECH_SCORES
  } from "./actions.type";
import {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,
    GET_BY_ID_REQUEST,
    GET_BY_ID_SUCCESS,
    GET_BY_ID_FAILURE,
    CLEAR_ITEMS,
    CLEAR_CURRENT_ITEM,
    UPDATE_CURRENT_ITEM,
    ADD_CONTACT,
    REMOVE_CONTACT,
    ADD_PROJECT,
    REMOVE_PROJECT,
    ADD_AREA,
    REMOVE_AREA,
    ADD_IDEA,
    REMOVE_IDEA,
    ADD_TECHNOLOGY_TYPE,
    REMOVE_TECHNOLOGY_TYPE,
    ADD_APPLICATION_TYPE,
    REMOVE_APPLICATION_TYPE,
    ADD_APPLICATION_STATE,
    REMOVE_APPLICATION_STATE,
    ADD_TAG,
    REMOVE_TAG,
    ADD_INTEGRATION_TAG,
    REMOVE_INTEGRATION_TAG,
    REMOVE_ALL_INTEGRATION_TAGS,
    ADD_INTEGRATION,
    SAVE_INTEGRATION,
    REMOVE_INTEGRATION,
    SET_CURRENT_INTEGRATION,
    CLEAR_CURRENT_INTEGRATION,
    UPDATE_CURRENT_INTEGRATION,
    SET_QUESTION_ANSWER,
    UPDATE_PORTFOLIO_FIELD,
    UPDATE_TECH_SCORES,
    UPDATE_BUSINESS_SCORES,
    
    ADD_APPLICATION_IT_SERVICE,
    SAVE_APPLICATION_IT_SERVICE,
    REMOVE_APPLICATION_IT_SERVICE,
    SET_CURRENT_APPLICATION_IT_SERVICE,
    CLEAR_CURRENT_APPLICATION_IT_SERVICE,
    UPDATE_CURRENT_APPLICATION_IT_SERVICE,

    SET_CURRENT_APPLICATION_CAPABILITY,
    CLEAR_CURRENT_APPLICATION_CAPABILITY,
    SAVE_APPLICATION_CAPABILITY,
    REMOVE_APPLICATION_CAPABILITY,
    CLEAR_APPLICATION_CAPABILITIES,

    SET_FRAMEWORK_ITEMS,

    SET_MANUFACTURER,
    SET_VENDOR
  } from "./mutations.type";
import {
    INTEGRATION_PUBLISH,
} from "@/helpers";


function getBlankProject() {
    return {
        applicationProjectId: GUID_EMPTY,
        applicationId: GUID_EMPTY,
        projectId: null,
        name: ""
    }
}

function getBlankContact() {
    return {
        applicationContactId: GUID_EMPTY,
        applicationId: GUID_EMPTY,
        contactId: null,
        refContactTypeId: null,
        name: "",
        refContactTypeName: ""
    }
}

function getBlankArea() {
    return {
        applicationAreaId: GUID_EMPTY,
        applicationId: GUID_EMPTY,
        areaId: null,
        name: ""
    };
}

function getBlankIdea() {
    return {
        ideaApplicationId: GUID_EMPTY,
        ideaId: GUID_EMPTY,
        name: ""
    };
}

function getBlankApplicationType() {
    return {
        applicationTypeId: GUID_EMPTY,
        applicationId: GUID_EMPTY,
        refApplicationTypeId: null,
        refApplicationTypeName: ""
    };
}

function getBlankTechnologyType() {
    return {
        applicationTechnologyTypeId: GUID_EMPTY,
        applicationId: GUID_EMPTY,
        refTechnologyTypeId: null,
        refTechnologyTypeName: ""
    };
}

function getBlankState() {
    return {
        applicationStateId: GUID_EMPTY,
        applicationId: GUID_EMPTY,
        refApplicationStateId: null,
        currentState: true,
        plannedDate: null,
        actualDate: null,
        notes: "",
        refApplicationStateName: ""
    };
}

function getBlankTag() {
    return {
        applicationTagId: GUID_EMPTY,
        applicationId: GUID_EMPTY,
        tagId: GUID_EMPTY,
        name: ""
    };
}

function getBlankIntegration() {
    return {
        applicationIntegrationId: GUID_EMPTY,
        refIntegrationTypeId: null,
        applicationId: GUID_EMPTY,
        otherApplicationId: GUID_EMPTY,
        direction: "",
        notes: "",
        publishingApplicationName: "",
        subscribingApplicationName: "",
        refIntegrationTypeName: "",
        // entityNames: "",
        applicationIntegrationTags: []
        // tagNames: ""
    };
}

function getBlankApplicationITService() {
    return {
        applicationITServiceId: GUID_EMPTY,
        applicationId: GUID_EMPTY,
        itServiceId: GUID_EMPTY,
        notes: "",
        itServiceName: "",
        applicationName: ""
    };
}

function getBlankApplicationCapability() {
    return {
        applicationCapabilityId: GUID_EMPTY,
        capabilityId: GUID_EMPTY,
        isPrimary: false,
        rank: null,
        score: null,
        name: ""
    }
}

function getBlankFrameworkItem() {
    return {
        applicationFrameworkId: GUID_EMPTY,
        applicationId: GUID_EMPTY,
        portfolioId: GUID_EMPTY,
        frameworkItemId: GUID_EMPTY,
        hierarchyId: ""
    };
}

function getBlankCapability() {
    return {
        applicationCapabilityId: GUID_EMPTY,
        capabilityId: GUID_EMPTY,
        name: "",
        isPrimary: false,
        rank: null,
        score: null
    };
}

function getBlankApplication() {
    return {
        applicationId: GUID_EMPTY,
        accountId: GUID_EMPTY,
        linkedITServiceId: null,
        name: "",
        color: null,
        shortDescription: "",
        longDescription: "",
        primaryUseCase: "",
        notes: "",
        systemOrDomain: 0,
        usedInternally: false,
        sourceList: "",
        lifecycleStartDate: null,
        lifecycleEndDate: null,
        lastReviewedDate: null,
        isThirdParty: false,
        isSuite: false,
        parentSuiteApplicationId: null,
        parentSuiteApplication: {},
        answeredBusinessQuestionsCount: 0,
        businessQuestionsScore: 0,
        criticality: 0,
        answeredTechnologyQuestionsCount: 0,
        technologyQuestionsScore: 0,
        technologyQuestionsScoreAdjustment: 0,
        technologyQuestionsNetScore:0,
        manufacturerSupplierId: null,
        manufacturerSupplier: {},
        vendorSupplierId: null,
        vendorSupplier: {},
        active: true,
        deleted: false,
        createdDate: null,
        createdBy: null,
        createdByName: null,
        modifiedDate: null,
        modifiedBy: null,
        modifiedByName: null,
        applicationAreas: [],
        applicationCapabilities: [],
        applicationContacts: [],
        applicationProjects: [],
        applicationQuestionAnswers: [],
        applicationStates: [],
        applicationTags: [],
        applicationTechnologyTypes: [],
        applicationTypes: [],
        ideaApplications: [],
        portfolios: [],
        integrations: [],
        applicationFrameworkItems: [],
        applicationITServices: [],
        typesDescription: null,
        technologyTypesDescription: null,
        projectsDescription: null,
        areasDescription: null,
        businessAreasDescription: null,
        ownerContactsDescription: null,
        expertContactsDescription: null,
        primarySupportContactsDescription: null,
        secondarySupportContactsDescription: null
    };
}

export const applications = {
    namespaced: true,
    state: {
        status: {
            loading: false,
            loaded: false,
            failed: false,
            error: null
        },
        items: [],
        currentApplication: getBlankApplication(),
        currentIntegration: getBlankIntegration(),
        // newIntegrationTemporaryId: -1,
        currentApplicationITService: getBlankApplicationITService(),
        currentApplicationCapability: getBlankApplicationCapability(),
        // newApplicationITServiceTemporaryId: -1
    },
    getters: {
        getSuiteApplications: (state) => (id) => {
            return state.items.filter(item => item.isSuite);
        },
        getActivePortfolioApplications: (state) => (id) => {
            return state.items.filter(item => item.portfolioId === id);
        },
        getCurrentApplicationBusinessQuestions: state => {
            return state.currentApplication.applicationQuestionAnswers.filter(q => q.questionType === "Business");
        },
        getCurrentApplicationTechnologyQuestions: state => {
            return state.currentApplication.applicationQuestionAnswers.filter(q => q.questionType === "Technology");
        },
        getCurrentApplicationCurrentStates: state => {
            return state.currentApplication.applicationStates.filter(s => s.currentState);
        },
        getApplicationChartData: state => {
            return [{
                key: state.currentApplication.name,
                values: [{
                      name: state.currentApplication.name,
                      label: state.currentApplication.name,
                      //appId: app.applicationId,
                      x: state.currentApplication.technologyQuestionsNetScore,
                      y: state.currentApplication.businessQuestionsScore,
                      size: state.currentApplication.criticality,   //Configure the size of each scatter point
                      shape: "circle"  //Configure the shape of each scatter point.
                    }]
            }];
        },
        getCurrentApplicationQuestionSelectedAnswer: (state) => (applicationQuestionAnswerId) => {
            //debugger
            let qa = state.currentApplication.applicationQuestionAnswers.find(x => x.applicationQuestionAnswerId == applicationQuestionAnswerId);
            let selectedAnswer = qa.answers.find(a => a.questionAnswerId == qa.questionAnswerId);
            return selectedAnswer;
        }
    },

    actions: {
        [GET_ALL]({ commit, state }, useCached) {
            return new Promise((resolve, reject) => {
                if (!!useCached && state.items.length > 0) {
                    resolve(state.items);
                } else {
                    commit(GET_ALL_REQUEST);

                    applicationService.getAll()
                        .then(
                            applications => {
                                commit(GET_ALL_SUCCESS, applications);
                                resolve(applications);
                            },
                            error =>  {
                                commit(GET_ALL_FAILURE, error);
                                reject(error);
                            }
                        );
                }
            });
        },
        [GET_BY_PORTFOLIO_ID]({ commit }, portfolioId) {
            return new Promise((resolve, reject) => {
                commit(GET_ALL_REQUEST);

                applicationService.getByPortfolio(portfolioId)
                    .then(
                        applications => {
                            commit(GET_ALL_SUCCESS, applications);
                            resolve(applications);
                        },
                        error =>  {
                            commit(GET_ALL_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [GET_BY_ID]({ commit }, applicationId) {
            return new Promise((resolve, reject) => {
                commit(GET_BY_ID_REQUEST);

                return applicationService.getById(applicationId)
                    .then(
                        application => {
                            commit(GET_BY_ID_SUCCESS, application);
                            resolve(application);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [GET_NEW]({ commit }, portfolioId) {
            return new Promise((resolve, reject) => {
                commit(GET_BY_ID_REQUEST);

                return applicationService.getNew(portfolioId)
                    .then(
                        application => {
                            commit(GET_BY_ID_SUCCESS, application);
                            resolve(application);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },        
        [ADD]({ dispatch, commit }, application) {
            return applicationService.add(application);
        },
        [UPDATE]({ dispatch, commit }, { application, portfolioId }) {
            return applicationService.update(application, portfolioId);
        },
        [DELETE]({ dispatch, commit }, application) {
            return applicationService.deleteApplication(application);
        },
        [CALCULATE_TECH_SCORES]({ dispatch, commit }, application) {
            return new Promise((resolve, reject) => {
                return applicationService.calculateTechScores(application)
                    .then(
                        techScores => {
                            commit(UPDATE_TECH_SCORES, techScores);
                            resolve(techScores);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        }
    },
    mutations: {
        [GET_ALL_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_ALL_SUCCESS](state, applications) {
            state.status = { loaded: true };
            state.items = applications;
        },
        [GET_ALL_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.items = [];
        },
        [GET_BY_ID_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_BY_ID_SUCCESS](state, application) {
            state.status = { loaded: true };
            state.currentApplication = application;
        },
        [GET_BY_ID_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentApplication = getBlankApplication();
        },
        [CLEAR_ITEMS](state) {
            state.items = [];
        },
        [CLEAR_CURRENT_ITEM](state) {
            state.currentApplication = getBlankApplication();
        },
        [UPDATE_CURRENT_ITEM](state, { field, value }) {
            // see https://ypereirareis.github.io/blog/2017/04/25/vuejs-two-way-data-binding-state-management-vuex-strict-mode/
            Object.assign(state.currentApplication, {
                [field]: value
            });
        },
        [UPDATE_PORTFOLIO_FIELD](state, { portfolioId, field, value }) {
            var portfolio = state.currentApplication.portfolios.find(a => a.portfolioId == portfolioId)
            Object.assign(portfolio, {
                [field]: value
            });
        },        
        [ADD_CONTACT](state, { contactId, contactTypeId }) {
            var newContact = getBlankContact();
            newContact.contactId = contactId;
            newContact.refContactTypeId = contactTypeId;
            state.currentApplication.applicationContacts.push(newContact);
        },
        [REMOVE_CONTACT](state, { contactId, contactTypeId }) {
            let index = _.findIndex(state.currentApplication.applicationContacts, { 'contactId': contactId, 'refContactTypeId': contactTypeId });
            if (index > -1) {
                state.currentApplication.applicationContacts.splice(index, 1);
            }
            // _.remove(state.currentApplication.applicationContacts, { 'contactId': contactId, 'refContactTypeId': contactTypeId });
        },
        [ADD_PROJECT](state, projectId) {
            var newProject = getBlankProject();
            newProject.projectId = projectId;
            state.currentApplication.applicationProjects.push(newProject);
        },
        [REMOVE_PROJECT](state, projectId) {
            let index = _.findIndex(state.currentApplication.applicationProjects, { 'projectId': projectId });
            if (index > -1) {
                state.currentApplication.applicationProjects.splice(index, 1);
            }
            // _.remove(state.currentApplication.applicationProjects, { 'projectId': projectId });
        },
        [ADD_AREA](state, areaId) {
            var newArea = getBlankArea();
            newArea.areaId = areaId;
            state.currentApplication.applicationAreas.push(newArea);
        },
        [REMOVE_AREA](state, areaId) {
            let index = _.findIndex(state.currentApplication.applicationAreas, { 'areaId': areaId });
            if (index > -1) {
                state.currentApplication.applicationAreas.splice(index, 1);
            }
            //_.remove(state.currentApplication.applicationAreas, { 'areaId': areaId });
        },
        [ADD_IDEA](state, idea) {
            var newIdea = getBlankIdea();
            newIdea.ideaId = idea.ideaId;
            newIdea.name = idea.name;
            state.currentApplication.ideaApplications.push(newIdea);
        },
        [REMOVE_IDEA](state, idea) {
            let index = _.findIndex(state.currentApplication.ideaApplications, { 'ideaId': idea.ideaId });
            if (index > -1) {
                state.currentApplication.ideaApplications.splice(index, 1);
            }
        },        
        [ADD_TECHNOLOGY_TYPE](state, technologyTypeId) {
            var newEntry = getBlankTechnologyType();
            newEntry.refTechnologyTypeId = technologyTypeId;
            state.currentApplication.applicationTechnologyTypes.push(newEntry);
        },
        [REMOVE_TECHNOLOGY_TYPE](state, technologyTypeId) {
            let index = _.findIndex(state.currentApplication.applicationTechnologyTypes, { 'refTechnologyTypeId': technologyTypeId });
            if (index > -1) {
                state.currentApplication.applicationTechnologyTypes.splice(index, 1);
            }
            // _.remove(state.currentApplication.applicationTechnologyTypes, { 'refTechnologyTypeId': technologyTypeId });
        },
        [ADD_APPLICATION_TYPE](state, applicationTypeId) {
            var newEntry = getBlankApplicationType();
            newEntry.refApplicationTypeId = applicationTypeId;
            state.currentApplication.applicationTypes.push(newEntry);
        },
        [REMOVE_APPLICATION_TYPE](state, applicationTypeId) {
            let index = _.findIndex(state.currentApplication.applicationTypes, { 'refApplicationTypeId': applicationTypeId });
            if (index > -1) {
                state.currentApplication.applicationTypes.splice(index, 1);
            }
            // _.remove(state.currentApplication.applicationTypes, { 'refApplicationTypeId': applicationTypeId });
        },
        [ADD_APPLICATION_STATE](state, applicationStateId) {
            // first clear the states
            _.remove(state.currentApplication.applicationStates, function(val) { return true });
            // add the new state
            var newEntry = getBlankState();
            newEntry.refApplicationStateId = applicationStateId;
            newEntry.currentState = true;
            state.currentApplication.applicationStates.push(newEntry);
        },
        [REMOVE_APPLICATION_STATE](state, applicationStateId) {
            let index = _.findIndex(state.currentApplication.applicationStates, { 'refApplicationStateId': applicationStateId });
            if (index > -1) {
                state.currentApplication.applicationStates.splice(index, 1);
            }
            // _.remove(state.currentApplication.applicationStates, { 'refApplicationStateId': applicationStateId });
        },
        [ADD_TAG](state, { tagId, name }) {
            var newEntry = getBlankTag();
            newEntry.tagId = tagId;
            newEntry.name = name;
            state.currentApplication.applicationTags.push(newEntry);
        },
        [REMOVE_TAG](state, tagId) {
            // the vue-multiselect doesn't recognize a _.remove to modify the array in place, the array must be replaced for it to update
            state.currentApplication.applicationTags = state.currentApplication.applicationTags.filter((tag) => tag.tagId !== tagId);
            
            state.currentApplication.integrations.forEach(integration => {
                if (integration.direction == INTEGRATION_PUBLISH) {
                    integration.applicationIntegrationTags = integration.applicationIntegrationTags.filter(integrationTag => integrationTag.tagId != tagId);
                }
            })
        },

        [SET_MANUFACTURER](state, supplier) {
            if (supplier && supplier.supplierId) {
                state.currentApplication.manufacturerSupplier = supplier;
                state.currentApplication.manufacturerSupplierId = supplier.supplierId;
            } else {
                state.currentApplication.manufacturerSupplier = null;
                state.currentApplication.manufacturerSupplierId = null;
            }
        },
        [SET_VENDOR](state, supplier) {
            if (supplier && supplier.supplierId) {
                state.currentApplication.vendorSupplier = supplier;
                state.currentApplication.vendorSupplierId = supplier.supplierId;
            } else {
                state.currentApplication.vendorSupplier = null;
                state.currentApplication.vendorSupplierId = null;
            }
        },
       

        [ADD_INTEGRATION](state) {
            var newEntry = getBlankIntegration();
            newEntry.applicationIntegrationId = uuidv4(); // state.newIntegrationTemporaryId;
            //state.newIntegrationTemporaryId = state.newIntegrationTemporaryId - 1;
            state.currentApplication.integrations.push(newEntry);
            state.currentIntegration = newEntry;
        },
        [SAVE_INTEGRATION](state, integration) {
            if (integration.applicationIntegrationId == GUID_EMPTY) {
                integration.applicationIntegrationId = uuidv4(); //state.newIntegrationTemporaryId;
                // state.newIntegrationTemporaryId = state.newIntegrationTemporaryId - 1;
            }
            if (state.currentApplication.integrations.find(i => i.applicationIntegrationId == integration.applicationIntegrationId)) {
                Vue.set(state.currentApplication.integrations, 
                        state.currentApplication.integrations.findIndex(i => i.applicationIntegrationId == integration.applicationIntegrationId),
                        integration);
            } else {
                state.currentApplication.integrations.push(integration);
            }

            // check for new tags to add to the application, if current is the publish
            //  if subscribe, the publish app gets updated automatically at the server and we don't have to update it here for the UI
            if (integration.direction == INTEGRATION_PUBLISH) {
                integration.applicationIntegrationTags.forEach(integrationTag => {
                    let matchingTag = state.currentApplication.applicationTags.find(t => t.name == integrationTag.name);
                    if (!matchingTag) {
                        var newEntry = getBlankTag();
                        newEntry.tagId = integrationTag.tagId;
                        newEntry.name = integrationTag.name;
                        state.currentApplication.applicationTags.push(newEntry);
                    }
                });
            }

            state.currentIntegration = integration;
        },
        [REMOVE_INTEGRATION](state, applicationIntegrationId) {
            state.currentIntegration = getBlankIntegration();
            _.remove(state.currentApplication.integrations, { 'applicationIntegrationId': applicationIntegrationId });
            // trick to force Vue to see the array change - https://github.com/buefy/buefy/issues/86
            state.currentApplication.integrations = state.currentApplication.integrations.slice(0);
        },
        [SET_CURRENT_INTEGRATION](state, integration) {
            state.currentIntegration = integration;
        },
        [CLEAR_CURRENT_INTEGRATION](state) {
            state.currentIntegration = getBlankIntegration();
        },
        [UPDATE_CURRENT_INTEGRATION](state, { field, value }) {
            Object.assign(state.currentIntegration, {
                [field]: value
            });
        },

        [ADD_INTEGRATION_TAG](state, { tagId, name }) {
            var newEntry = getBlankTag();
            newEntry.tagId = tagId;
            newEntry.name = name;
            state.currentIntegration.applicationIntegrationTags.push(newEntry);
        },
        [REMOVE_INTEGRATION_TAG](state, tagId) {
            // the vue-multiselect doesn't recognize a _.remove to modify the array in place, the array must be replaced for it to update
            state.currentIntegration.applicationIntegrationTags = state.currentIntegration.applicationIntegrationTags.filter((tag) => tag.tagId !== tagId);
            
            // _.remove(state.currentApplication.applicationTags, { 'tagId': tagId });
            // state.currentApplication.applicationTags = state.currentApplication.applicationTags;
        },

        [REMOVE_ALL_INTEGRATION_TAGS](state) {
            state.currentIntegration.applicationIntegrationTags = [];
        },

        [SET_QUESTION_ANSWER](state, { accountQuestionId, questionAnswerId }) {
            var question = state.currentApplication.applicationQuestionAnswers.find(a => a.accountQuestionId == accountQuestionId);
            if (question !== undefined) {
                question.questionAnswerId = questionAnswerId;
                question.answerValue = 0;
                question.weightedAnswerValue = 0;
                question.suppliedNumericAnswer = null;
            }
        },
        [UPDATE_TECH_SCORES](state, { answeredTechnologyQuestionsCount, technologyQuestionsScore, technologyQuestionsNetScore}) {
            Object.assign(state.currentApplication, {
                answeredTechnologyQuestionsCount: answeredTechnologyQuestionsCount,
                technologyQuestionsScore: technologyQuestionsScore,
                technologyQuestionsNetScore: technologyQuestionsNetScore
            });
        },
        [UPDATE_BUSINESS_SCORES](state, { portfolioId, businessScores}) {
            var appPortfolio = state.currentApplication.portfolios.find(a => a.portfolioId == portfolioId);
            if (appPortfolio !== undefined) {
                Object.assign(appPortfolio, {
                    answeredBusinessQuestionsCount: businessScores.answeredBusinessQuestionsCount,
                    businessQuestionsScore: businessScores.businessQuestionsScore
                });
            }
        },
        [SET_FRAMEWORK_ITEMS](state, { portfolioId, items, selected}) {
            // take a list of frameworkItemIds and add or remove from current apps applicationFrameworkItems
            for (let item of items) {     
                // is the framework item already in the applicationFrameworkItems?
                let existingItemIndex = state.currentApplication.applicationFrameworkItems.findIndex(f => f.frameworkItemId == item && f.portfolioId == portfolioId);

                if (selected && existingItemIndex < 0) {
                    // add
                    let newEntry = getBlankFrameworkItem();
                    newEntry.frameworkItemId = item;
                    newEntry.portfolioId = portfolioId;
                    state.currentApplication.applicationFrameworkItems.push(newEntry);
                } else if (!selected && existingItemIndex >= 0) {
                    // remove
                    state.currentApplication.applicationFrameworkItems.splice(existingItemIndex, 1);
                }
            }
        },
        [ADD_APPLICATION_IT_SERVICE](state) {
            var newEntry = getBlankApplicationITService();
            newEntry.applicationITServiceId = uuidv4(); // state.newApplicationITServiceTemporaryId;
            // state.newApplicationITServiceTemporaryId = state.newApplicationITServiceTemporaryId - 1;
            state.currentApplication.applicationITServices.push(newEntry);
            state.currentApplicationITService = newEntry;
        },
        [SAVE_APPLICATION_IT_SERVICE](state, applicationITService) {
            if (applicationITService.applicationITServiceId == GUID_EMPTY) {
                applicationITService.applicationITServiceId = uuidv4(); // state.newApplicationITServiceTemporaryId;
                // state.newApplicationITServiceTemporaryId = state.newApplicationITServiceTemporaryId - 1;
            }
            if (state.currentApplication.applicationITServices.find(i => i.applicationITServiceId == applicationITService.applicationITServiceId)) {
                Vue.set(state.currentApplication.applicationITServices, 
                        state.currentApplication.applicationITServices.findIndex(i => i.applicationITServiceId == applicationITService.applicationITServiceId),
                        applicationITService);
            } else {
                state.currentApplication.applicationITServices.push(applicationITService);
            }

            state.currentApplicationITService = applicationITService;
        },
        [REMOVE_APPLICATION_IT_SERVICE](state, applicationITServiceId) {
            state.currentApplicationITService = getBlankApplicationITService();
            _.remove(state.currentApplication.applicationITServices, { 'applicationITServiceId': applicationITServiceId });
            // trick to force Vue to see the array change - https://github.com/buefy/buefy/issues/86
            state.currentApplication.applicationITServices = state.currentApplication.applicationITServices.slice(0);
        },
        [SET_CURRENT_APPLICATION_IT_SERVICE](state, applicationITService) {
            state.currentApplicationITService = applicationITService;
        },
        [CLEAR_CURRENT_APPLICATION_IT_SERVICE](state) {
            state.currentApplicationITService = getBlankApplicationITService();
        },
        [UPDATE_CURRENT_APPLICATION_IT_SERVICE](state, { field, value }) {
            Object.assign(state.currentApplicationITService, {
                [field]: value
            });
        },

        [SAVE_APPLICATION_CAPABILITY](state, applicationCapability) {
            if (applicationCapability.applicationCapabilityId == GUID_EMPTY) {
                applicationCapability.applicationCapabilityId = uuidv4(); // state.newApplicationCapabilityTemporaryId;
                // state.newApplicationCapabilityTemporaryId = state.newApplicationCapabilityTemporaryId - 1;
            }
            if (state.currentApplication.applicationCapabilities.find(i => i.applicationCapabilityId == applicationCapability.applicationCapabilityId)) {
                Vue.set(state.currentApplication.applicationCapabilities, 
                        state.currentApplication.applicationCapabilities.findIndex(i => i.applicationCapabilityId == applicationCapability.applicationCapabilityId),
                        applicationCapability);
            } else {
                state.currentApplication.applicationCapabilities.push(applicationCapability);
            }

            state.currentApplicationCapability = applicationCapability;
        },
        [REMOVE_APPLICATION_CAPABILITY](state, applicationCapabilityId) {
            state.currentApplicationCapability = getBlankApplicationCapability();
            _.remove(state.currentApplication.applicationCapabilities, { 'applicationCapabilityId': applicationCapabilityId });
            // trick to force Vue to see the array change - https://github.com/buefy/buefy/issues/86
            state.currentApplication.applicationCapabilities = state.currentApplication.applicationCapabilities.slice(0);
        },
        [SET_CURRENT_APPLICATION_CAPABILITY](state, applicationCapability) {
            state.currentApplicationCapability = applicationCapability;
        },
        [CLEAR_CURRENT_APPLICATION_CAPABILITY](state) {
            state.currentApplicationCapability = getBlankApplicationCapability();
        },
        [CLEAR_APPLICATION_CAPABILITIES](state) {
            state.currentApplication.applicationCapabilities = [];
        },

        // 
    }
}
