// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';
import { v4 as uuidv4 } from 'uuid';

export const userService = {
    login,
    logout,
    register,
    registerEmail,
    createSandbox,
    checkEmailExists,
    checkEmailExistsInAccount,
    getAll,
    getSystemAdministrators,
    getAbandonedRegistrationEmails,
    switchAccount,
    joinAccount,
    impersonateUser,
    endImpersonation,
    refreshUser,
    forgotPassword,
    resetPassword,
    changeMyPassword,
    changePassword,
    sendVerificationEmail,
    verifyEmail,
    getById,
    getByInviteToken,
    update,
    updateRoles,
    getUserPreferences,
    saveUserPreference,
    resetUserPreferences,
    cloneUserPreferences
};

function storeUserAndToken(user) {
    //console.log(user);
    // login successful if there's a jwt token in the response
    if (user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
        axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    }

    return user;
}

function storeTrialUserAndToken(trialUser) {
    //console.log(user);
    // login successful if there's a jwt token in the response
    if (trialUser.user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(trialUser.user));
        axios.defaults.headers.common['Authorization'] = `Bearer ${trialUser.user.token}`;
    }

    return trialUser;
}

function login(email, password, rememberMe) {
    let payload = { emailAddress: email, password: password, rememberMe: rememberMe };

    return axios.post('/users/authenticate', payload)
        .then(handleResponse)
        .then(storeUserAndToken);
}

function register(firstName, lastName, position, company, refCompanyEmployeeId, email, password, inviteToken, sampleData, agreeTerms) {
    let payload = { firstName, lastName, position, company, refCompanyEmployeeId, emailAddress: email, password, inviteToken, sampleData, agreeTerms };

    return axios.post('/users/register', payload)
        .then(handleResponse)
        .then(storeUserAndToken);
}

function registerEmail(email) {
    let payload = { emailAddress: email };

    return axios.post('/users/registeremail', payload)
        .then(handleResponse)
        .then(storeUserAndToken);
}

function createSandbox(firstName, lastName, position, company, email, refCompanyEmployeeId) {
    let payload = { firstName, lastName, position, company, emailAddress: email, refCompanyEmployeeId };

    return axios.post('/users/trial', payload)
        .then(handleResponse)
        .then(storeTrialUserAndToken);
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    // remove the axios default header
    delete axios.defaults.headers.common['Authorization'];
}

function checkEmailExists(emailAddress) {
    // return axios.get(`/users/checkemail?email=${encodeURIComponent(email)}&x=${uuidv4()}`).then(handleResponse);
    let payload = { emailAddress };
    return axios.post('/users/checkemail', payload)
        .then(handleResponse)
}

function checkEmailExistsInAccount(emailAddress) {
    // return axios.get(`/users/checkaccountemail?email=${encodeURIComponent(email)}&x=${uuidv4()}`).then(handleResponse);
    let payload = { emailAddress };
    return axios.post('/users/checkaccountemail', payload)
        .then(handleResponse)
}


function getAll() {
    return axios.get('/users').then(handleResponse);
}

function getSystemAdministrators() {
    return axios.get('/users/systemadministrators').then(handleResponse);
}

function getAbandonedRegistrationEmails() {
    return axios.get('/users/abandonedregistrationemails').then(handleResponse);
}

function getById(id) {
    return axios.get(`/users/${id}`).then(handleResponse);
}

function getByInviteToken(token) {
    return axios.get(`/users/invitetoken/${token}`).then(handleResponse);
}


function update(user, file) {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("userData", JSON.stringify(user));

    return axios.put(`/users/${user.userId}`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then(handleResponse);

    // return axios.put(`/users/${user.userId}`, user).then(handleResponse);
}
function updateRoles(user) {
    return axios.put(`/users/${user.userId}/roles`, user).then(handleResponse);
}

function switchAccount(accountId) {
    return axios.post('/users/switchaccount', { accountId })
        .then(handleResponse)
        .then(storeUserAndToken);
}

function joinAccount(accountId) {
    return axios.post('/users/joinaccount', { accountId })
        .then(handleResponse)
        .then(storeUserAndToken);
}

function impersonateUser(impersonateUserId) {
    return axios.post('/users/impersonate', { impersonateUserId })
        .then(handleResponse)
        .then(storeUserAndToken);
}

function endImpersonation() {
    return axios.post('/users/endimpersonation')
        .then(handleResponse)
        .then(storeUserAndToken);
}

function refreshUser() {
    return axios.post('/users/refreshuser')
        .then(handleResponse)
        .then(storeUserAndToken);
}

function forgotPassword(emailAddress) {
    return axios.post('/users/forgotpassword', { emailAddress }).then(handleResponse);
}

function resetPassword(emailAddress, password, verification) {
    return axios.post('/users/resetpassword', { emailAddress, password, verification })
        .then(handleResponse)
        .then(storeUserAndToken);
}

function changeMyPassword(oldPassword, newPassword) {
    return axios.post('/users/changemypassword', { oldPassword, newPassword })
        .then(handleResponse)
        .then(storeUserAndToken);
}

function changePassword(userId, newPassword) {
    return axios.post(`/users/${userId}/changepassword`, { newPassword })
        .then(handleResponse);
}

function sendVerificationEmail(emailAddress) {
    return axios.post('/users/sendverification', { emailAddress }).then(handleResponse);
}

function verifyEmail(emailAddress, verification) {
    return axios.post('/users/verifyemail', { emailAddress, verification })
        .then(handleResponse)
        .then(storeUserAndToken);
}

function getUserPreferences() {
    return axios.get('/users/preferences').then(handleResponse);
}

function saveUserPreference(preferenceName, preferenceValue, globalPreference) {
    return axios.post('/users/preferences', { preferenceName, preferenceValue, globalPreference })
        .then(handleResponse);
}

function resetUserPreferences(id) {
    return axios.post(`/users/${id}/resetpreferences`)
        .then(handleResponse);
}

function cloneUserPreferences(id, cloneUserId) {
    return axios.post(`/users/${id}/clonepreferences`, { userId: cloneUserId })
        .then(handleResponse);
}
