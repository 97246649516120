import Vue from 'vue'

import { AgGridVue } from "ag-grid-vue";
import 'ag-grid-enterprise';

import { LicenseManager } from  'ag-grid-enterprise';
LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-052252}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Allstar_Technologies_Canada_Limited}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GetInSync}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GetInSync}_need_to_be_licensed___{GetInSync}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{15_February_2025}____[v3]_[01]_MTczOTU3NzYwMDAwMA==b200011ece0644def2daae17df45b636");

Vue.component('ag-grid', AgGridVue);

import AgGridButtons from '@/components/grid/AgGridButtons';
Vue.component('ag-grid-buttons', AgGridButtons);

import AgGridQuickFilter from '@/components/grid/AgGridQuickFilter';
Vue.component('ag-grid-quick-filter', AgGridQuickFilter);
