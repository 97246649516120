import _ from 'lodash';
import { portfolioService } from '@/services';
import { GUID_EMPTY } from '@/helpers';
import {
    GET_ALL,
    GET_SUMMARIES,
    GET_BY_ID,
    GET_FILTERS,
    ADD,
    UPDATE,
    UPDATE_ORDER,
    RESET_ORDER,
    UPDATE_SECURITY,
    DELETE,
    ADD_APPLICATION,
    REMOVE_APPLICATION,
    UPDATE_APPLICATION,
    CALCULATE_BUSINESS_SCORES,
    APPLY_BUSINESS_FACTORS
} from "./actions.type";
import {
    GET_ALL_REQUEST,
    GET_ALL_SUCCESS,
    GET_ALL_FAILURE,
    GET_SUMMARY_REQUEST,
    GET_SUMMARY_SUCCESS,
    GET_SUMMARY_FAILURE,
    GET_BY_ID_REQUEST,
    GET_BY_ID_SUCCESS,
    GET_BY_ID_FAILURE,
    CLEAR_ITEMS,
    CLEAR_CURRENT_ITEM,
    GET_FILTERS_REQUEST,
    GET_FILTERS_SUCCESS,
    GET_FILTERS_FAILURE,
    SET_SELECTED_PORTFOLIO,
    UPDATE_PORTFOLIO_NAME,
    UPDATE_PORTFOLIO_MANAGER,
    CRUD_REQUEST,
    CRUD_SUCCESS,
    CRUD_FAILURE,
    UPDATE_BUSINESS_SCORES,
    SET_PORTFOLIO_ROLE
} from "./mutations.type";

function getBlankRole() {
    return {
        userRoleId: GUID_EMPTY,
        userId: null,
        roleId: null,
        partnerId: null,
        accountId: null,
        portfolioId: null,
        active: true,
        deleted: false,
        createdDate: null,
        createdBy: null,
        modifiedDate: null,
        modifiedBy: null,
        role: {
            roleId: GUID_EMPTY,
            roleName: "",
            roleRights: []
        },
        roleName: "",
        rights: []
    };
}

function getBlankPortfolio() {
    return {
        portfolioId: null,
        accountId: null,
        name: "",
        displayOrder: null,
        businessQuestionsCount: 0,
        businessQuestionsPointWeight: 0,
        technologyQuestionsCount: 0,
        technologyQuestionsPointWeight: 0,
        managerContactId: null,
        managerContact: {},
        applicationsCount: 0,
        licensesCount: 0,
        processesSupportedCount: 0,
        applicationsToBeRetiredCount: 0,
        licenseCost: 0,
        personnelCost: 0,
        hardwareCost: 0,
        refreshCost: 0,
        developmentCost: 0,
        hardwareAndHostingCost: 0,
        totalCost: 0,
        thirdPartyApplicationsCount: 0,
        thirdPartyLicensesCount: 0,
        thirdPartyProcessesSupportedCount: 0,
        thirdPartyApplicationsToBeRetiredCount: 0,
        thirdPartyLicenseCost: 0,
        thirdPartyPersonnelCost: 0,
        thirdPartyHardwareCost: 0,
        thirdPartyRefreshCost: 0,
        thirdPartyDevelopmentCost: 0,
        thirdPartyHardwareAndHostingCost: 0,
        thirdPartyTotalCost: 0,
        grandTotalApplicationsCount: 0,
        grandTotalLicensesCount: 0,
        grandTotalProcessesSupportedCount: 0,
        grandTotalApplicationsToBeRetiredCount: 0,
        grandTotalLicenseCost: 0,
        grandTotalPersonnelCost: 0,
        grandTotalHardwareCost: 0,
        grandTotalRefreshCost: 0,
        grandTotalDevelopmentCost: 0,
        grandTotalHardwareAndHostingCost: 0,
        grandTotalCost: 0,            
        portfolioArchitectureScore: 0,
        active: true,
        deleted: false,
        createdDate: null,
        createdBy: null,
        modifiedDate: null,
        modifiedBy: null,
        applications: [],
        roles: [],
        projects: [],
        ideas: []
    }
}

export const portfolios = {
    namespaced: true,
    state: {
        status: {
            loading: false,
            loaded: false,
            failed: false,
            error: null
        },
        selectedPortfolioId: null,
        items: [],
        summaryItems: [],
        currentPortfolio: getBlankPortfolio(),
        currentPortfolioAppFilters: {
            businessAreas: [
                {
                    areaId: null,
                    refAreaTypeId: null,
                    refAreaTypeName: "",
                    name: "",
                    chosen: false
                }
            ],
            ownerContacts: [
                {
                    contactId: null,
                    refContactTypeId: null,
                    refContactTypeName: "",
                    name: "",
                    position: "",
                    area: "",
                    company: "",
                    emailAddress: "",
                    contactPhone: "",
                    chosen: false
                }
            ],
            expertContacts: [
                {
                    contactId: null,
                    refContactTypeId: null,
                    refContactTypeName: "",
                    name: "",
                    position: "",
                    area: "",
                    company: "",
                    emailAddress: "",
                    contactPhone: "",
                    chosen: false
                }
            ],
            applicationStates: [
                {
                    refApplicationStateId: null,
                    name: "",
                    chosen: false
                }
            ],
            technologyTypes: [
                {
                    refTechnologyTypeId: null,
                    name: "",
                    chosen: false
                }
            ],
            applicationTypes: [
                {
                    refApplicationTypeId: null,
                    name: "",
                    chosen: false
                }
            ],
            projectStatuses: [
                {
                    status: null,
                    chosen: false
                }
            ],
            projects: [
                {
                    projectId: null,
                    refProjectTypeId: null,
                    refProjectTypeName: "",
                    name: "",
                    initiationDate: null,
                    targetDate: null,
                    completionDate: null,
                    notes: "",
                    active: true,
                    chosen: false
                }
            ],
            ideas: [
                {
                    ideaId: null,
                    name: "",
                    active: true,
                    chosen: false
                }
            ]
        }
    },
    getters: {
        activePortfolio: state => {
            return state.items.find(item => item.portfolioId === state.selectedPortfolioId);
        },
        getPortfolioById: (state) => (id) => {
            return state.items.find(item => item.portfolioId === id);
        },
        getApplicationChartData: state => {
            return [{
                key: state.currentPortfolio.name,
                values: _.map(state.currentPortfolio.applications, app => {
                    return {
                      name: app.name,
                      label: app.name,
                      appId: app.applicationId,
                      x: app.technologyQuestionsNetScore,
                      y: app.businessQuestionsScore,
                      size: app.criticality,   //Configure the size of each scatter point
                      shape: "circle"  //Configure the shape of each scatter point.
                    }
                })
            }]
        },
        getAllPortfoliosChartData: state => {
            return _.map(state.items.filter(p => p.applications.length > 0), i => {
                return {
                    key: i.name,
                    values: _.map(i.applications, app => {
                        return {
                        name: app.name,
                        label: app.name,
                        appId: app.applicationId,
                        x: app.technologyQuestionsNetScore,
                        y: app.businessQuestionsScore,
                        size: app.criticality,   //Configure the size of each scatter point
                        shape: "circle"  //Configure the shape of each scatter point.
                        }
                    })
                }
            })
        }
    },
    actions: {
        [GET_ALL]({ commit, state }, useCached) {
            return new Promise((resolve, reject) => {
                if (!!useCached && state.items.length) {
                    resolve(state.items);
                } else {
                    commit(GET_ALL_REQUEST);

                    return portfolioService.getAll()
                        .then(
                            portfolios => {
                                commit(GET_ALL_SUCCESS, portfolios);
                                resolve(portfolios);
                            },
                            error => {
                                commit(GET_ALL_FAILURE, error);
                                reject(error);
                            }
                        );
                }
            });
        },
        [GET_SUMMARIES]({ commit, state }, useCached) {
            return new Promise((resolve, reject) => {
                if (!!useCached && state.items.length) {
                    resolve(state.items);
                } else {
                    commit(GET_SUMMARY_REQUEST);

                    return portfolioService.getSummaries()
                        .then(
                            portfolios => {
                                commit(GET_SUMMARY_SUCCESS, portfolios);
                                resolve(portfolios);
                            },
                            error => {
                                commit(GET_SUMMARY_FAILURE, error);
                                reject(error);
                            }
                        );
                }
            });
        },
        [GET_BY_ID]({ commit }, portfolioId) {
            return new Promise((resolve, reject) => {
                commit(GET_BY_ID_REQUEST);

                return portfolioService.getById(portfolioId)
                    .then(
                        portfolio => {
                            commit(GET_BY_ID_SUCCESS, portfolio);
                            resolve(portfolio);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [GET_FILTERS]({ commit }, portfolioId) {
            return new Promise((resolve, reject) => {
                commit(GET_FILTERS_REQUEST);

                return portfolioService.getAppFiltersById(portfolioId)
                    .then(
                        filters => {
                            commit(GET_FILTERS_SUCCESS, filters);
                            resolve(filters);
                        },
                        error => {
                            commit(GET_FILTERS_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },

        [ADD]({ dispatch, commit }, portfolio) {
            return portfolioService.add(portfolio);
        },
        [UPDATE]({ dispatch, commit }, portfolio) {
            return portfolioService.update(portfolio);
        },
        [UPDATE_ORDER]({ dispatch, commit }, portfolioList) {
            return portfolioService.updateOrder(portfolioList);
        },
        [RESET_ORDER]({ dispatch, commit }) {
            return portfolioService.resetOrder();
        },
        [UPDATE_SECURITY]({ dispatch, commit }, portfolio) {
            return portfolioService.updateSecurity(portfolio);
        },
        [DELETE]({ dispatch, commit }, portfolio) {
            return portfolioService.deletePortfolio(portfolio);
        },
        [ADD_APPLICATION]({ commit }, { portfolioId, applicationId }) {
            return new Promise((resolve, reject) => {
                commit(CRUD_REQUEST);

                return portfolioService.addPortfolioApplication(portfolioId, applicationId)
                    .then(
                        portfolio => {
                            commit(CRUD_SUCCESS, portfolio);
                            commit(GET_BY_ID_SUCCESS, portfolio);
                            resolve(portfolio);
                        },
                        error => {
                            commit(CRUD_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },        
        [REMOVE_APPLICATION]({ commit }, { portfolioId, applicationId }) {
            return new Promise((resolve, reject) => {
                commit(CRUD_REQUEST);

                return portfolioService.removePortfolioApplication(portfolioId, applicationId)
                    .then(
                        portfolio => {
                            commit(CRUD_SUCCESS, portfolio);
                            commit(GET_BY_ID_SUCCESS, portfolio);
                            resolve(portfolio);
                        },
                        error => {
                            commit(CRUD_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },      
        [UPDATE_APPLICATION]({ commit }, { portfolioId, applicationId, application }) {
            return new Promise((resolve, reject) => {
                commit(CRUD_REQUEST);

                return portfolioService.updatePortfolioApplication(portfolioId, applicationId, application)
                    .then(
                        data => {
                            commit(CRUD_SUCCESS, data);
                            resolve(data);
                        },
                        error => {
                            commit(CRUD_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },      
        [CALCULATE_BUSINESS_SCORES]({ dispatch, commit }, { portfolioId, application }) {
            var applicationId = application.applicationId;
            return new Promise((resolve, reject) => {
                return portfolioService.calculateBusinessScores(portfolioId, application)
                    .then(
                        businessScores => {
                            commit(UPDATE_BUSINESS_SCORES, {applicationId, businessScores});
                            resolve(businessScores);
                        },
                        error => {
                            commit(GET_BY_ID_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        },
        [APPLY_BUSINESS_FACTORS]({ commit }, { portfolioId, application, applyToPortfolios, applyToSuiteChildren }) {
            const applicationId = application.applicationId;
            return new Promise((resolve, reject) => {
                commit(CRUD_REQUEST);

                return portfolioService.applyBusinessFactors(portfolioId, applicationId, application, applyToPortfolios, applyToSuiteChildren)
                    .then(
                        data => {
                            commit(CRUD_SUCCESS, data);
                            resolve(data);
                        },
                        error => {
                            commit(CRUD_FAILURE, error);
                            reject(error);
                        }
                    );
            });
        }        

    },
    mutations: {
        [SET_SELECTED_PORTFOLIO](state, value) {
            state.selectedPortfolioId = value;
        },
        [UPDATE_PORTFOLIO_NAME](state, name) {
            state.currentPortfolio.name = name;
        },
        [UPDATE_PORTFOLIO_MANAGER](state, managerId) {
            state.currentPortfolio.managerContactId = managerId;
        },
        [GET_ALL_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_ALL_SUCCESS](state, portfolios) {
            state.status = { loaded: true };
            state.items = portfolios;
        },
        [GET_ALL_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.items = [];
        },
        [GET_SUMMARY_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_SUMMARY_SUCCESS](state, portfolios) {
            state.status = { loaded: true };
            state.summaryItems = portfolios;
        },
        [GET_SUMMARY_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.summaryItems = [];
        },
        [GET_BY_ID_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_BY_ID_SUCCESS](state, portfolio) {
            state.status = { loaded: true };
            state.currentPortfolio = portfolio;
        },
        [GET_BY_ID_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentPortfolio = getBlankPortfolio();
        },
        [CLEAR_ITEMS](state) {
            state.items = [];
        },
        [CLEAR_CURRENT_ITEM](state) {
            state.currentPortfolio = getBlankPortfolio();
        },
        [GET_FILTERS_REQUEST](state) {
            state.status = { loading: true };
        },
        [GET_FILTERS_SUCCESS](state, filters) {
            state.status = { loaded: true };
            state.currentPortfolioAppFilters = filters;
        },
        [GET_FILTERS_FAILURE](state, error) {
            state.status = { failed: true, error };
            state.currentPortfolioAppFilters = null;
        },
        [CRUD_REQUEST](state) {
            state.status = { loading: true };
        },
        [CRUD_SUCCESS](state, data) {
            state.status = { loaded: true };
        },
        [CRUD_FAILURE](state, error) {
            state.status = { failed: true, error };
        },
        [UPDATE_BUSINESS_SCORES](state, { applicationId, businessScores}) {
            var appPortfolio = state.currentPortfolio.applications.find(a => a.applicationId == applicationId);
            if (appPortfolio !== undefined) {
                Object.assign(appPortfolio, {
                    answeredBusinessQuestionsCount: businessScores.answeredBusinessQuestionsCount,
                    businessQuestionsScore: businessScores.businessQuestionsScore
                });
            }
        },
        [SET_PORTFOLIO_ROLE](state, { accountId, userId, roleName }) {
            _.remove(state.currentPortfolio.roles, { 'userId': userId });
            var newRole = getBlankRole();
            newRole.accountId = accountId;
            newRole.userId = userId;
            newRole.roleName = roleName;
            state.currentPortfolio.roles.push(newRole);
        },
    }
}
