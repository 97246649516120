import VueRouter from 'vue-router'

// router setup
import routes from '../routes/routes'

import { store } from '@/store';


// configure router
const router = new VueRouter({
    mode: 'history',
    routes, // short for routes: routes
    linkActiveClass: 'active',
    scrollBehavior: (to) => {
      if (to.hash) {
        return {selector: to.hash}
      } else {
        return { x: 0, y: 0 }
      }
    }
  });
  
  router.beforeEach((to, from, next) => {
      // NProgress.start();
      // redirect to login page if not logged in and trying to access a restricted page
      //const publicPages = ['/login', '/register', '/'];
      //const authRequired = !publicPages.includes(to.path);
      //debugger
      const authRequired = !to.matched.some(route => route.meta.allowAnonymous);
      const sysAdminRequired = to.matched.some(route => route.meta.sysAdminRequired);
      const loggedIn = store.getters['authentication/isAuthenticated'];
      const isSystemAdministrator = store.getters['authentication/isSystemAdministrator'];
      const isHomePage = (to.path === '/');
      const user = store.getters['authentication/loggedInUser'];
    
      // auth required but not logged in
      if ((authRequired || sysAdminRequired) && !loggedIn) {
        return next('/login');
      }
  
      // this is a temporary check for our conversion from ids to guids - if the user is logged in with an id, they need to login again to get a guid
      if (loggedIn && user.userId.length != 36 && to.path != '/login') {
        return next('/login');
      }
    
      if (loggedIn && isHomePage) {
        return next('/overview');
      }
    
      // maybe temporary, redirect straight to login if they hit the home page
      if (isHomePage) {
        return next('/login');
      }
  
      // is system admin required
      if (sysAdminRequired && !isSystemAdministrator) {
        return next('/overview');
      }
  
      // email address not verified
      if (loggedIn && !user.emailAddressVerified && to.path != '/overview' && to.path != '/admin/verifyemail' && to.path != '/emailverification' && to.path != '/verifyemail' && to.path != '/logout') {
        NProgress.done();
        return next('/overview');
      }
      
      
      // no role specified, just authenticated
      if (authRequired 
            && !to.matched.some(route => route.meta.role) 
            && !to.matched.some(route => route.meta.roles)
            && !to.matched.some(route => route.meta.right) 
            && !to.matched.some(route => route.meta.rights)) {
        return next();
      }
    
      // single role specified, doesn't match
      if (authRequired 
            && to.matched.some(route => route.meta.role)
            && !to.matched.some(route => store.getters['authentication/isInRole'](route.meta.role))) {
        return next('/overview');
      }
    
      // multiple roles specified, doesn't match
      if (authRequired 
        && to.matched.some(route => route.meta.roles)
        && !to.matched.some(route => route.meta.roles.some(role => store.getters['authentication/isInRole'](role)))) {
        return next('/overview');
      }
    
      // single right specified, doesn't match
      if (authRequired 
        && to.matched.some(route => route.meta.right)
        && !to.matched.some(route => store.getters['authentication/hasRightOrPortfolioRight'](route.meta.right))) {
        return next('/overview');
      }
    
      // multiple rights specified, doesn't match
      if (authRequired && to.matched.some(route => route.meta.rights) ) {
          // debugger;
          var matchedRoutes = to.matched.filter(route => route.meta.rights);
          // check that every matching route (parent/children) with rights has access
          if (!matchedRoutes.every(route => route.meta.rights.some(right => store.getters['authentication/hasRightOrPortfolioRight'](right)))) {
              return next('/overview');
          }
      }
  
      next();
});


export { router };