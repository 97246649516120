// import config from 'config';
import axios from 'axios';
import { handleResponse } from './http-utils';

export const portfolioService = {
    getAll,
    getSummaries,
    add,
    update,
    updateOrder,
    resetOrder,
    updateSecurity,
    deletePortfolio,
    getById,
    getProgramsById,
    getAppFiltersById,
    addPortfolioApplication,
    updatePortfolioApplication,
    removePortfolioApplication,
    calculateBusinessScores,
    applyBusinessFactors,
    updatePortfolioApplicationFrameworkItems
};

function getAll() {
    return axios.get('/portfolios').then(handleResponse);
}

function getSummaries() {
    return axios.get('/portfolios/summaries').then(handleResponse);
}

function add(portfolio) {
    return axios.post('/portfolios', portfolio).then(handleResponse);
}

function update(portfolio) {
    return axios.put(`/portfolios/${portfolio.portfolioId}`, portfolio).then(handleResponse);
}

function updateOrder(portfolioList) {
    return axios.post(`/portfolios/setorder`, portfolioList).then(handleResponse);
}

function resetOrder() {
    return axios.post(`/portfolios/resetorder`).then(handleResponse);
}

function updateSecurity(portfolio) {
    return axios.put(`/portfolios/${portfolio.portfolioId}/security`, portfolio).then(handleResponse);
}

function deletePortfolio(portfolio) {
    return axios.delete(`/portfolios/${portfolio.portfolioId}`).then(handleResponse);
}

function getById(id) {
    return axios.get(`/portfolios/${id}`).then(handleResponse);
}

function getProgramsById(id) {
    return axios.get(`/portfolios/${id}/programs`).then(handleResponse);
}

function getAppFiltersById(id) {
    return axios.get(`/portfolios/${id}/applicationfiltervalues`).then(handleResponse);
}

function addPortfolioApplication(portfolioId, applicationId) {
    var application = { applicationId };
    return axios.post(`/portfolios/${portfolioId}/applications/`, application).then(handleResponse);
}

function updatePortfolioApplication(portfolioId, applicationId, application) {
    return axios.put(`/portfolios/${portfolioId}/applications/${applicationId}`, application).then(handleResponse);
}

function removePortfolioApplication(portfolioId, applicationId) {
    return axios.delete(`/portfolios/${portfolioId}/applications/${applicationId}`).then(handleResponse);
}

function calculateBusinessScores(portfolioId, application) {
    return axios.post(`/portfolios/${portfolioId}/applications/businessscores`, application).then(handleResponse);
}

function applyBusinessFactors(portfolioId, applicationId, application, applyToPortfolios, applyToSuiteChildren) {
    return axios.post(`/portfolios/${portfolioId}/applications/${applicationId}/applybusinessfactors`, { application, applyToPortfolios, applyToSuiteChildren }).then(handleResponse);
}

function updatePortfolioApplicationFrameworkItems(portfolioId, payload) {
    return axios.put(`/portfolios/${portfolioId}/applications/frameworkitems`, payload).then(handleResponse);
}

