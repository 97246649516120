<template>
    <div class="mb-2">
        <k-button :theme-color="'secondary'" class="k-btn-sm" @click.prevent="resetColumns()">
            <i class="fa fa-undo"></i> Reset Columns
        </k-button>
        <k-button :theme-color="'secondary'" class="k-btn-sm" @click.prevent="expandAll()" v-if="isGrouped">
            <i class="fa fa-expand"></i> Expand All
        </k-button>
        <k-button :theme-color="'secondary'" class="k-btn-sm" @click.prevent="collapseAll()" v-if="isGrouped">
            <i class="fa fa-compress"></i> Collapse All
        </k-button>

        {{ gridRecordCount }}

        <k-button :theme-color="'primary'" class="k-btn-sm float-end" @click.prevent="addNew()" v-if="showAddNew">
            <i class="fa fa-plus"></i> Add
        </k-button>
    </div>
</template>

<script>

export default {
    props: {
        gridApi: Object,
        columnApi: Object,
        showAddNew: Boolean,
        recordCount: Number // can be used to provide record count directly if gridApi is not available/functioning
    },
    data () {
        return {
            
        };
    },
    computed: {
        gridRecordCount: function() {
            if (this.recordCount != null) {
                if (this.recordCount == 1) {
                    return `${this.recordCount} record`;
                } else {
                    return `${this.recordCount} records`;
                }
            }

            if (this.gridApi && this.gridApi.paginationIsLastPageFound()) {
                const dataSize = this.gridApi.paginationGetRowCount();
                if (dataSize == 1) {
                    return `${dataSize} record`;
                } else {
                    return `${dataSize} records`;
                }
            }

            return '';
        },
        isGrouped: function() {
            return this.gridApi && this.gridApi.getRowGroupColumns().length;
        },
    },
    methods: {
       resetColumns() {
            if (this.columnApi) {
                this.columnApi.resetColumnState();
                this.$emit('state-changed');
            }
        },
        expandAll() {
            if (this.gridApi) {
                this.gridApi.expandAll();
                this.$emit('state-changed');
            }
        },
        collapseAll() {
            if (this.gridApi) {
                this.gridApi.collapseAll();
                this.$emit('state-changed');
            }
        },

        addNew() {
            this.$emit('add-new');
        }
    }
}
</script>
