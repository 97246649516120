// common
export const GET_ALL_REQUEST = 'getAllRequest';
export const GET_ALL_FAILURE = 'getAllFailure';
export const GET_ALL_SUCCESS = 'getAllSuccess';
export const GET_ACTIVE_REQUEST = 'getActiveRequest';
export const GET_ACTIVE_SUCCESS = 'getActiveFailure';
export const GET_ACTIVE_FAILURE = 'getActiveSuccess';
export const GET_SUMMARY_REQUEST = 'getSummaryRequest';
export const GET_SUMMARY_FAILURE = 'getSummaryFailure';
export const GET_SUMMARY_SUCCESS = 'getSummarySuccess';
export const GET_BY_ID_REQUEST = 'getByIdRequest';
export const GET_BY_ID_FAILURE = 'getByIdFailure';
export const GET_BY_ID_SUCCESS = 'getByIdSuccess';
export const GET_REQUEST = 'getRequest';
export const GET_FAILURE = 'getFailure';
export const GET_SUCCESS = 'getSuccess';
export const CRUD_REQUEST = 'crudRequest';
export const CRUD_FAILURE = 'crudFailure';
export const CRUD_SUCCESS = 'crudSuccess';
export const CLEAR_ITEMS = 'clearItems';
export const CLEAR_CURRENT_ITEM = 'clearCurrentItem';
export const UPDATE_CURRENT_ITEM = 'updateCurrentItem';
export const UPDATE_LIST_ITEM = 'updateListItem';
export const UPDATE_FILTER = 'updateFilter';

export const VIEW_BOARD = 'viewBoard';
export const VIEW_GRID = 'viewGrid';
export const SET_FILTER = 'setFilter';

export const ADD_FILTER_PRIORITY = 'addFilterPriority';
export const REMOVE_FILTER_PRIORITY = 'removeFilterPriority';
export const ADD_FILTER_PORTFOLIO = 'addFilterPortfolio';
export const REMOVE_FILTER_PORTFOLIO = 'removeFilterPortfolio';
export const ADD_FILTER_TYPE = 'addFilterType';
export const REMOVE_FILTER_TYPE = 'removeFilterType';
export const ADD_FILTER_PROGRAM = 'addFilterProgram';
export const REMOVE_FILTER_PROGRAM = 'removeFilterProgram';

export const ADD_FILTER_OWNER = 'addFilterOwner';
export const REMOVE_FILTER_OWNER = 'removeFilterOwner';
export const ADD_FILTER_SPONSOR = 'addFilterSponsor';
export const REMOVE_FILTER_SPONSOR = 'removeFilterSponsor';
export const ADD_FILTER_INITIATED_BY = 'addFilterInitiatedBy';
export const REMOVE_FILTER_INITIATED_BY = 'removeFilterInitiatedBy';

export const SET_EXPANDED_ITEMS = 'setExpandedItems';

// authentication
export const LOGIN_REQUEST = 'loginRequest';
export const LOGIN_FAILURE = 'loginFailure';
export const LOGIN_SUCCESS = 'loginSuccess';
export const PROCESS_LOGOUT = 'logout';
export const SWITCH_ACCOUNT_REQUEST = 'switchAccountRequest';
export const SWITCH_ACCOUNT_FAILURE = 'switchAccountFailure';
export const SWITCH_ACCOUNT_SUCCESS = 'switchAccountSuccess';
export const JOIN_ACCOUNT_REQUEST = 'joinAccountRequest';
export const JOIN_ACCOUNT_FAILURE = 'joinAccountFailure';
export const JOIN_ACCOUNT_SUCCESS = 'joinAccountSuccess';
export const IMPERSONATE_REQUEST = 'impersonateRequest';
export const IMPERSONATE_FAILURE = 'impersonateFailure';
export const IMPERSONATE_SUCCESS = 'impersonateSuccess';
export const END_IMPERSONATION_REQUEST = 'endImpersonationRequest';
export const END_IMPERSONATION_FAILURE = 'endImpersonationFailure';
export const END_IMPERSONATION_SUCCESS = 'endImpersonationSuccess';
export const REFRESH_USER_REQUEST = 'refreshUserRequest';
export const REFRESH_USER_FAILURE = 'refreshUserFailure';
export const REFRESH_USER_SUCCESS = 'refreshUserSuccess';
export const FORGOT_PASSWORD_REQUEST = 'forgotPasswordRequest';
export const FORGOT_PASSWORD_FAILURE = 'forgotPasswordFailure';
export const FORGOT_PASSWORD_SUCCESS = 'forgotPasswordSuccess';
export const RESET_PASSWORD_REQUEST = 'resetPasswordRequest';
export const RESET_PASSWORD_FAILURE = 'resetPasswordFailure';
export const RESET_PASSWORD_SUCCESS = 'resetPasswordSuccess';
export const CHANGE_MY_PASSWORD_REQUEST = 'changeMyPasswordRequest';
export const CHANGE_MY_PASSWORD_FAILURE = 'changeMyPasswordFailure';
export const CHANGE_MY_PASSWORD_SUCCESS = 'changeMyPasswordSuccess';
export const CHANGE_PASSWORD_REQUEST = 'changePasswordRequest';
export const CHANGE_PASSWORD_FAILURE = 'changePasswordFailure';
export const CHANGE_PASSWORD_SUCCESS = 'changePasswordSuccess';
export const SEND_EMAIL_VERIFY_REQUEST = 'sendEmailVerifyRequest';
export const SEND_EMAIL_VERIFY_FAILURE = 'sendEmailVerifyFailure';
export const SEND_EMAIL_VERIFY_SUCCESS = 'sendEmailVerifySuccess';
export const VERIFY_EMAIL_REQUEST = 'verifyEmailRequest';
export const VERIFY_EMAIL_FAILURE = 'verifyEmailFailure';
export const VERIFY_EMAIL_SUCCESS = 'verifyEmailSuccess';
export const CREATE_TRIAL_REQUEST = 'createTrialRequest';
export const CREATE_TRIAL_FAILURE = 'createTrialFailure';
export const CREATE_TRIAL_SUCCESS = 'createTrialSuccess';
export const REGISTER_REQUEST = 'registerRequest';
export const REGISTER_FAILURE = 'registerFailure';
export const REGISTER_SUCCESS = 'registerSuccess';
export const REGISTER_SET_EMAIL_REQUEST = 'registerSetEmailRequest'
export const REGISTER_SET_EMAIL_FAILURE = 'registerSetEmailFailure'
export const REGISTER_SET_EMAIL_SUCCESS = 'registerSetEmailSuccess'

// namespaced auth
export const AUTHENTICATION_NAMESPACE = 'authentication';
export const AUTHENTICATION_LOGIN_REQUEST = `${AUTHENTICATION_NAMESPACE}/${LOGIN_REQUEST}`;
export const AUTHENTICATION_LOGIN_FAILURE = `${AUTHENTICATION_NAMESPACE}/${LOGIN_FAILURE}`;
export const AUTHENTICATION_LOGIN_SUCCESS = `${AUTHENTICATION_NAMESPACE}/${LOGIN_SUCCESS}`;
export const AUTHENTICATION_PROCESS_LOGOUT = `${AUTHENTICATION_NAMESPACE}/${PROCESS_LOGOUT}`;
export const AUTHENTICATION_SWITCH_ACCOUNT_REQUEST = `${AUTHENTICATION_NAMESPACE}/${SWITCH_ACCOUNT_REQUEST}`;
export const AUTHENTICATION_SWITCH_ACCOUNT_FAILURE = `${AUTHENTICATION_NAMESPACE}/${SWITCH_ACCOUNT_FAILURE}`;
export const AUTHENTICATION_SWITCH_ACCOUNT_SUCCESS = `${AUTHENTICATION_NAMESPACE}/${SWITCH_ACCOUNT_SUCCESS}`;
export const AUTHENTICATION_JOIN_ACCOUNT_REQUEST = `${AUTHENTICATION_NAMESPACE}/${JOIN_ACCOUNT_REQUEST}`;
export const AUTHENTICATION_JOIN_ACCOUNT_FAILURE = `${AUTHENTICATION_NAMESPACE}/${JOIN_ACCOUNT_FAILURE}`;
export const AUTHENTICATION_JOIN_ACCOUNT_SUCCESS = `${AUTHENTICATION_NAMESPACE}/${JOIN_ACCOUNT_SUCCESS}`;
export const AUTHENTICATION_IMPERSONATE_REQUEST = `${AUTHENTICATION_NAMESPACE}/${IMPERSONATE_REQUEST}`;
export const AUTHENTICATION_IMPERSONATE_FAILURE = `${AUTHENTICATION_NAMESPACE}/${IMPERSONATE_FAILURE}`;
export const AUTHENTICATION_IMPERSONATE_SUCCESS = `${AUTHENTICATION_NAMESPACE}/${IMPERSONATE_SUCCESS}`;
export const AUTHENTICATION_END_IMPERSONATION_REQUEST = `${AUTHENTICATION_NAMESPACE}/${END_IMPERSONATION_REQUEST}`;
export const AUTHENTICATION_END_IMPERSONATION_FAILURE = `${AUTHENTICATION_NAMESPACE}/${END_IMPERSONATION_FAILURE}`;
export const AUTHENTICATION_END_IMPERSONATION_SUCCESS = `${AUTHENTICATION_NAMESPACE}/${END_IMPERSONATION_SUCCESS}`;
export const AUTHENTICATION_FORGOT_PASSWORD_REQUEST = `${AUTHENTICATION_NAMESPACE}/${FORGOT_PASSWORD_REQUEST}`;
export const AUTHENTICATION_FORGOT_PASSWORD_FAILURE = `${AUTHENTICATION_NAMESPACE}/${FORGOT_PASSWORD_FAILURE}`;
export const AUTHENTICATION_FORGOT_PASSWORD_SUCCESS = `${AUTHENTICATION_NAMESPACE}/${FORGOT_PASSWORD_SUCCESS}`;
export const AUTHENTICATION_RESET_PASSWORD_REQUEST = `${AUTHENTICATION_NAMESPACE}/${RESET_PASSWORD_REQUEST}`;
export const AUTHENTICATION_RESET_PASSWORD_FAILURE = `${AUTHENTICATION_NAMESPACE}/${RESET_PASSWORD_FAILURE}`;
export const AUTHENTICATION_RESET_PASSWORD_SUCCESS = `${AUTHENTICATION_NAMESPACE}/${RESET_PASSWORD_SUCCESS}`;
export const AUTHENTICATION_SEND_EMAIL_VERIFY_REQUEST = `${AUTHENTICATION_NAMESPACE}/${SEND_EMAIL_VERIFY_REQUEST}`;
export const AUTHENTICATION_SEND_EMAIL_VERIFY_FAILURE = `${AUTHENTICATION_NAMESPACE}/${SEND_EMAIL_VERIFY_FAILURE}`;
export const AUTHENTICATION_SEND_EMAIL_VERIFY_SUCCESS = `${AUTHENTICATION_NAMESPACE}/${SEND_EMAIL_VERIFY_SUCCESS}`;
export const AUTHENTICATION_VERIFY_EMAIL_REQUEST = `${AUTHENTICATION_NAMESPACE}/${VERIFY_EMAIL_REQUEST}`;
export const AUTHENTICATION_VERIFY_EMAIL_FAILURE = `${AUTHENTICATION_NAMESPACE}/${VERIFY_EMAIL_FAILURE}`;
export const AUTHENTICATION_VERIFY_EMAIL_SUCCESS = `${AUTHENTICATION_NAMESPACE}/${VERIFY_EMAIL_SUCCESS}`;
export const AUTHENTICATION_CREATE_TRIAL_REQUEST = `${AUTHENTICATION_NAMESPACE}/${CREATE_TRIAL_REQUEST}`;
export const AUTHENTICATION_CREATE_TRIAL_FAILURE = `${AUTHENTICATION_NAMESPACE}/${CREATE_TRIAL_FAILURE}`;
export const AUTHENTICATION_CREATE_TRIAL_SUCCESS = `${AUTHENTICATION_NAMESPACE}/${CREATE_TRIAL_SUCCESS}`;
export const AUTHENTICATION_REGISTER_REQUEST = `${AUTHENTICATION_NAMESPACE}/${REGISTER_REQUEST}`;
export const AUTHENTICATION_REGISTER_FAILURE = `${AUTHENTICATION_NAMESPACE}/${REGISTER_FAILURE}`;
export const AUTHENTICATION_REGISTER_SUCCESS = `${AUTHENTICATION_NAMESPACE}/${REGISTER_SUCCESS}`;
export const AUTHENTICATION_REGISTER_SET_EMAIL_REQUEST = `${AUTHENTICATION_NAMESPACE}/${REGISTER_SET_EMAIL_REQUEST}`;
export const AUTHENTICATION_REGISTER_SET_EMAIL_FAILURE = `${AUTHENTICATION_NAMESPACE}/${REGISTER_SET_EMAIL_FAILURE}`;
export const AUTHENTICATION_REGISTER_SET_EMAIL_SUCCESS = `${AUTHENTICATION_NAMESPACE}/${REGISTER_SET_EMAIL_SUCCESS}`;

// account
export const UPDATE_ADDRESS = 'updateAddress';
export const GET_REFERENCE_VALUES_REQUEST = 'getReferenceValuesRequest';
export const GET_REFERENCE_VALUES_FAILURE = 'getReferenceValuesFailure';
export const GET_REFERENCE_VALUES_SUCCESS = 'getReferenceValuesSuccess';
export const GET_FRAMEWORKS_REQUEST = 'getFrameworksRequest';
export const GET_FRAMEWORKS_FAILURE = 'getFrameworksFailure';
export const GET_FRAMEWORKS_SUCCESS = 'getFrameworksSuccess';
export const GET_FLAT_FRAMEWORKS_REQUEST = 'getFlatFrameworksRequest';
export const GET_FLAT_FRAMEWORKS_FAILURE = 'getFlatFrameworksFailure';
export const GET_FLAT_FRAMEWORKS_SUCCESS = 'getFlatFrameworksSuccess';
export const GET_MY_WORKSPACES_REQUEST = 'getMyWorkspacesRequest';
export const GET_MY_WORKSPACES_FAILURE = 'getMyWorkspacesFailure';
export const GET_MY_WORKSPACES_SUCCESS = 'getMyWorkspacesSuccess';
export const GET_SUBSCRIPTION_REQUEST = 'getSubscriptionRequest';
export const GET_SUBSCRIPTION_FAILURE = 'getSubscriptionFailure';
export const GET_SUBSCRIPTION_SUCCESS = 'getSubscriptionSuccess';
export const SET_SELECTED_ACCOUNT = 'setSelectedAccount';
export const REMOVE_USER_REQUEST = 'removeUserRequest';
export const REMOVE_USER_FAILURE = 'removeUserFailure';
export const REMOVE_USER_SUCCESS = 'removeUserSuccess';
export const INVITE_USER_REQUEST = 'inviteUserRequest';
export const INVITE_USER_FAILURE = 'inviteUserFailure';
export const INVITE_USER_SUCCESS = 'inviteUserSuccess';
export const RESEND_INVITE_REQUEST = 'resendInviteRequest';
export const RESEND_INVITE_FAILURE = 'resendInviteFailure';
export const RESEND_INVITE_SUCCESS = 'resendInviteSuccess';
export const CANCEL_INVITE_REQUEST = 'cancelInviteRequest';
export const CANCEL_INVITE_FAILURE = 'cancelInviteFailure';
export const CANCEL_INVITE_SUCCESS = 'cancelInviteSuccess';
export const SETUP_REQUEST = 'setupRequest';
export const SETUP_FAILURE = 'setupFailure';
export const SETUP_SUCCESS = 'setupSuccess';
export const GET_RECENT_REQUEST = 'getRecentRequest';
export const GET_RECENT_FAILURE = 'getRecentFailure';
export const GET_RECENT_SUCCESS = 'getRecentSuccess';
export const SWITCH_SUBSCRIPTION_REQUEST = 'switchSubscriptionRequest';
export const SWITCH_SUBSCRIPTION_SUCCESS = 'switchSubscriptionSuccess';
export const SWITCH_SUBSCRIPTION_FAILURE = 'switchSubscriptionFailure';
export const SET_PAYMENT_METHOD_REQUEST = 'setPaymentMethodRequest';
export const SET_PAYMENT_METHOD_SUCCESS = 'setPaymentMethodSuccess';
export const SET_PAYMENT_METHOD_FAILURE = 'setPaymentMethodFailure';

export const ADD_DOMAIN = 'addDomain';
export const SAVE_DOMAIN = 'saveDomain';
export const REMOVE_DOMAIN = 'removeDomain';
export const SET_CURRENT_DOMAIN = 'setCurrentDomain';
export const CLEAR_CURRENT_DOMAIN = 'clearCurrentDomain';
export const UPDATE_CURRENT_DOMAIN = 'updateCurrentDomain';

// namespaced account
export const ACCOUNT_NAMESPACE = 'account';
export const ACCOUNT_GET_ALL_REQUEST = `${ACCOUNT_NAMESPACE}/${GET_ALL_REQUEST}`;
export const ACCOUNT_GET_ALL_FAILURE = `${ACCOUNT_NAMESPACE}/${GET_ALL_FAILURE}`;
export const ACCOUNT_GET_ALL_SUCCESS = `${ACCOUNT_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const ACCOUNT_GET_REFERENCE_VALUES_REQUEST = `${ACCOUNT_NAMESPACE}/${GET_REFERENCE_VALUES_REQUEST}`;
export const ACCOUNT_GET_REFERENCE_VALUES_FAILURE = `${ACCOUNT_NAMESPACE}/${GET_REFERENCE_VALUES_FAILURE}`;
export const ACCOUNT_GET_REFERENCE_VALUES_SUCCESS = `${ACCOUNT_NAMESPACE}/${GET_REFERENCE_VALUES_SUCCESS}`;
export const ACCOUNT_GET_FRAMEWORKS_REQUEST = `${ACCOUNT_NAMESPACE}/${GET_FRAMEWORKS_REQUEST}`;
export const ACCOUNT_GET_FRAMEWORKS_FAILURE = `${ACCOUNT_NAMESPACE}/${GET_FRAMEWORKS_FAILURE}`;
export const ACCOUNT_GET_FRAMEWORKS_SUCCESS = `${ACCOUNT_NAMESPACE}/${GET_FRAMEWORKS_SUCCESS}`;
export const ACCOUNT_GET_SUBSCRIPTION_REQUEST = `${ACCOUNT_NAMESPACE}/${GET_SUBSCRIPTION_REQUEST}`;
export const ACCOUNT_GET_SUBSCRIPTION_FAILURE = `${ACCOUNT_NAMESPACE}/${GET_SUBSCRIPTION_FAILURE}`;
export const ACCOUNT_GET_SUBSCRIPTION_SUCCESS = `${ACCOUNT_NAMESPACE}/${GET_SUBSCRIPTION_SUCCESS}`;
export const ACCOUNT_SET_SELECTED_ACCOUNT = `${ACCOUNT_NAMESPACE}/${SET_SELECTED_ACCOUNT}`;
export const ACCOUNT_GET_REQUEST = `${ACCOUNT_NAMESPACE}/${GET_REQUEST}`;
export const ACCOUNT_GET_FAILURE = `${ACCOUNT_NAMESPACE}/${GET_FAILURE}`;
export const ACCOUNT_GET_SUCCESS = `${ACCOUNT_NAMESPACE}/${GET_SUCCESS}`;
export const ACCOUNT_UPDATE_CURRENT_ITEM = `${ACCOUNT_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;
export const ACCOUNT_UPDATE_ADDRESS = `${ACCOUNT_NAMESPACE}/${UPDATE_ADDRESS}`;

export const ACCOUNT_ADD_DOMAIN = `${ACCOUNT_NAMESPACE}/${ADD_DOMAIN}`;
export const ACCOUNT_SAVE_DOMAIN = `${ACCOUNT_NAMESPACE}/${SAVE_DOMAIN}`;
export const ACCOUNT_REMOVE_DOMAIN = `${ACCOUNT_NAMESPACE}/${REMOVE_DOMAIN}`;
export const ACCOUNT_SET_CURRENT_DOMAIN = `${ACCOUNT_NAMESPACE}/${SET_CURRENT_DOMAIN}`;
export const ACCOUNT_CLEAR_CURRENT_DOMAIN = `${ACCOUNT_NAMESPACE}/${CLEAR_CURRENT_DOMAIN}`;
export const ACCOUNT_UPDATE_CURRENT_DOMAIN = `${ACCOUNT_NAMESPACE}/${UPDATE_CURRENT_DOMAIN}`;


// subscriptions
export const RATES_GET_BY_ID_REQUEST = 'getRateRequest';
export const RATES_GET_BY_ID_FAILURE = 'getRateFailure';
export const RATES_GET_BY_ID_SUCCESS = 'getRateSuccess';
export const RATES_CLEAR_CURRENT_ITEM = 'clearCurrentRate';
export const RATES_UPDATE_CURRENT_ITEM = 'updateCurrentRate';

// namespaced subscriptions
export const PRODUCTS_NAMESPACE = 'products';
export const PRODUCTS_GET_ACTIVE_REQUEST = `${PRODUCTS_NAMESPACE}/${GET_ACTIVE_REQUEST}`;
export const PRODUCTS_GET_ACTIVE_FAILURE = `${PRODUCTS_NAMESPACE}/${GET_ACTIVE_FAILURE}`;
export const PRODUCTS_GET_ACTIVE_SUCCESS = `${PRODUCTS_NAMESPACE}/${GET_ACTIVE_SUCCESS}`;

export const PRODUCTS_GET_BY_ID_REQUEST = `${PRODUCTS_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const PRODUCTS_GET_BY_ID_FAILURE = `${PRODUCTS_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const PRODUCTS_GET_BY_ID_SUCCESS = `${PRODUCTS_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const PRODUCTS_CLEAR_ITEMS = `${PRODUCTS_NAMESPACE}/${CLEAR_ITEMS}`;
export const PRODUCTS_CLEAR_CURRENT_ITEM = `${PRODUCTS_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const PRODUCTS_UPDATE_CURRENT_ITEM = `${PRODUCTS_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;

export const PRODUCTS_RATES_GET_BY_ID_REQUEST = `${PRODUCTS_NAMESPACE}/${RATES_GET_BY_ID_REQUEST}`;
export const PRODUCTS_RATES_GET_BY_ID_FAILURE = `${PRODUCTS_NAMESPACE}/${RATES_GET_BY_ID_FAILURE}`;
export const PRODUCTS_RATES_GET_BY_ID_SUCCESS = `${PRODUCTS_NAMESPACE}/${RATES_GET_BY_ID_SUCCESS}`;
export const PRODUCTS_RATES_CLEAR_CURRENT_ITEM = `${PRODUCTS_NAMESPACE}/${RATES_CLEAR_CURRENT_ITEM}`;
export const PRODUCTS_RATES_UPDATE_CURRENT_ITEM = `${PRODUCTS_NAMESPACE}/${RATES_UPDATE_CURRENT_ITEM}`;


// namespaced coupons
export const COUPONS_NAMESPACE = 'coupons';
export const COUPONS_GET_ALL_REQUEST = `${COUPONS_NAMESPACE}/${GET_ALL_REQUEST}`;
export const COUPONS_GET_ALL_FAILURE = `${COUPONS_NAMESPACE}/${GET_ALL_FAILURE}`;
export const COUPONS_GET_ALL_SUCCESS = `${COUPONS_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const COUPONS_GET_BY_ID_REQUEST = `${COUPONS_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const COUPONS_GET_BY_ID_FAILURE = `${COUPONS_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const COUPONS_GET_BY_ID_SUCCESS = `${COUPONS_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const COUPONS_CLEAR_ITEMS = `${COUPONS_NAMESPACE}/${CLEAR_ITEMS}`;
export const COUPONS_CLEAR_CURRENT_ITEM = `${COUPONS_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const COUPONS_UPDATE_CURRENT_ITEM = `${COUPONS_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;


// transactions
export const GET_PENDING_SETUP_REQUEST = 'getPendingSetupRequest';
export const GET_PENDING_SETUP_FAILURE = 'getPendingSetupFailure';
export const GET_PENDING_SETUP_SUCCESS = 'getPendingSetupSuccess';
export const GET_PAYMENT_SETUP_REQUEST = 'getPaymentSetupRequest';
export const GET_PAYMENT_SETUP_FAILURE = 'getPaymentSetupFailure';
export const GET_PAYMENT_SETUP_SUCCESS = 'getPaymentSetupSuccess';

// overview
export const GET_COMMON_VALUES_REQUEST = 'getCommonValuesRequest';
export const GET_COMMON_VALUES_SUCCESS = 'getCommonValuesFailure';
export const GET_COMMON_VALUES_FAILURE = 'getCommonValuesSuccess';

// namespaced overview
export const OVERVIEW_NAMESPACE = 'overview';
export const OVERVIEW_GET_REQUEST = `${OVERVIEW_NAMESPACE}/${GET_REQUEST}`;
export const OVERVIEW_GET_FAILURE = `${OVERVIEW_NAMESPACE}/${GET_FAILURE}`;
export const OVERVIEW_GET_SUCCESS = `${OVERVIEW_NAMESPACE}/${GET_SUCCESS}`;

// applications
export const ADD_CONTACT = 'addContact';
export const REMOVE_CONTACT = 'removeContact';
export const ADD_PROJECT = 'addProject';
export const REMOVE_PROJECT = 'removeProject';
export const ADD_AREA = 'addArea';
export const REMOVE_AREA = 'removeArea';
export const ADD_TECHNOLOGY_TYPE = 'addTechnologyType';
export const REMOVE_TECHNOLOGY_TYPE = 'removeTechnologyType';
export const ADD_APPLICATION_TYPE = 'addApplicationType';
export const REMOVE_APPLICATION_TYPE = 'removeApplicationType';
export const ADD_APPLICATION_STATE = 'addApplicationState';
export const REMOVE_APPLICATION_STATE = 'removeApplicationState';
export const ADD_INTEGRATION = 'addIntegration';
export const SAVE_INTEGRATION = 'saveIntegration';
export const REMOVE_INTEGRATION = 'removeIntegration';
export const ADD_TAG = 'addTag';
export const REMOVE_TAG = 'removeTag';
export const ADD_INTEGRATION_TAG = 'addIntegrationTag';
export const REMOVE_INTEGRATION_TAG = 'removeIntegrationTag';
export const REMOVE_ALL_INTEGRATION_TAGS = 'removeAllIntegrationTags';

export const SET_CURRENT_INTEGRATION = 'setCurrentIntegration';
export const CLEAR_CURRENT_INTEGRATION = 'clearCurrentIntegration';
export const UPDATE_CURRENT_INTEGRATION = 'updateCurrentIntegration';

export const SET_QUESTION_ANSWER = 'setQuestionAnswer';
export const UPDATE_PORTFOLIO_FIELD = 'updatePortfolioField';
export const UPDATE_TECH_SCORES = 'updateTechScores';
export const UPDATE_BUSINESS_SCORES = 'updateBusinessScores';

export const SET_TSM_DATA = 'setTSMData';
export const SET_APQC_DATA = 'setAPQCData';
export const SET_FRAMEWORK_ITEMS = 'setFrameworkItems';

// apps and it services
export const ADD_CLASSIFICATION = 'addClassification';
export const REMOVE_CLASSIFICATION = 'removeClassification';
export const ADD_COMPUTING_TYPE = 'addComputingType';
export const REMOVE_COMPUTING_TYPE = 'removeComputingType';
export const ADD_PLATFORM = 'addPlatform';
export const REMOVE_PLATFORM = 'removePlatform';

export const ADD_IDEA = 'addIdea';
export const REMOVE_IDEA = 'removeIdea';

export const ADD_APPLICATION_IT_SERVICE = 'addUsedByApplication';
export const SAVE_APPLICATION_IT_SERVICE = 'saveUsedByApplication';
export const REMOVE_APPLICATION_IT_SERVICE = 'removeUsedByApplication';
export const SET_CURRENT_APPLICATION_IT_SERVICE = 'setCurrentUsedByApplication';
export const CLEAR_CURRENT_APPLICATION_IT_SERVICE = 'clearCurrentUsedByApplication';
export const UPDATE_CURRENT_APPLICATION_IT_SERVICE = 'updateCurrentUsedByApplication';

export const ADD_IT_SERVICE_IT_SERVICE = 'addUsedByITService';
export const SAVE_IT_SERVICE_IT_SERVICE = 'saveUsedByITService';
export const REMOVE_IT_SERVICE_IT_SERVICE = 'removeUsedByITService';
export const SAVE_USES_IT_SERVICE = 'saveUsesITService';
export const REMOVE_USES_IT_SERVICE = 'removeUsesITService';

export const SET_CURRENT_IT_SERVICE_IT_SERVICE = 'setCurrentUsedByITService';
export const CLEAR_CURRENT_IT_SERVICE_IT_SERVICE = 'clearCurrentUsedByITService';
export const UPDATE_CURRENT_IT_SERVICE_IT_SERVICE = 'updateCurrentUsedByITService';

export const SET_MANUFACTURER = 'setManufacturer';
export const SET_VENDOR = 'setVendor';


export const SET_CURRENT_APPLICATION_CAPABILITY = 'setCurrentApplicationCapability';
export const CLEAR_CURRENT_APPLICATION_CAPABILITY = 'clearCurrentApplicationCapability';
export const SAVE_APPLICATION_CAPABILITY = 'saveApplicationCapability';
export const REMOVE_APPLICATION_CAPABILITY = 'removeApplicationCapability';
export const CLEAR_APPLICATION_CAPABILITIES = 'clearApplicationCapabilities';

// namespaced applications
export const APPLICATIONS_NAMESPACE = 'applications';
export const APPLICATIONS_GET_ALL_REQUEST = `${APPLICATIONS_NAMESPACE}/${GET_ALL_REQUEST}`;
export const APPLICATIONS_GET_ALL_FAILURE = `${APPLICATIONS_NAMESPACE}/${GET_ALL_FAILURE}`;
export const APPLICATIONS_GET_ALL_SUCCESS = `${APPLICATIONS_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const APPLICATIONS_GET_BY_ID_REQUEST = `${APPLICATIONS_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const APPLICATIONS_GET_BY_ID_FAILURE = `${APPLICATIONS_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const APPLICATIONS_GET_BY_ID_SUCCESS = `${APPLICATIONS_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const APPLICATIONS_CLEAR_ITEMS = `${APPLICATIONS_NAMESPACE}/${CLEAR_ITEMS}`;
export const APPLICATIONS_CLEAR_CURRENT_ITEM = `${APPLICATIONS_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const APPLICATIONS_UPDATE_CURRENT_ITEM = `${APPLICATIONS_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;

export const APPLICATIONS_ADD_CONTACT = `${APPLICATIONS_NAMESPACE}/${ADD_CONTACT}`;
export const APPLICATIONS_REMOVE_CONTACT = `${APPLICATIONS_NAMESPACE}/${REMOVE_CONTACT}`;
export const APPLICATIONS_ADD_PROJECT = `${APPLICATIONS_NAMESPACE}/${ADD_PROJECT}`;
export const APPLICATIONS_REMOVE_PROJECT = `${APPLICATIONS_NAMESPACE}/${REMOVE_PROJECT}`;
export const APPLICATIONS_ADD_AREA = `${APPLICATIONS_NAMESPACE}/${ADD_AREA}`;
export const APPLICATIONS_REMOVE_AREA = `${APPLICATIONS_NAMESPACE}/${REMOVE_AREA}`;
export const APPLICATIONS_ADD_IDEA = `${APPLICATIONS_NAMESPACE}/${ADD_IDEA}`;
export const APPLICATIONS_REMOVE_IDEA = `${APPLICATIONS_NAMESPACE}/${REMOVE_IDEA}`;
export const APPLICATIONS_ADD_TECHNOLOGY_TYPE = `${APPLICATIONS_NAMESPACE}/${ADD_TECHNOLOGY_TYPE}`;
export const APPLICATIONS_REMOVE_TECHNOLOGY_TYPE = `${APPLICATIONS_NAMESPACE}/${REMOVE_TECHNOLOGY_TYPE}`;
export const APPLICATIONS_ADD_APPLICATION_TYPE = `${APPLICATIONS_NAMESPACE}/${ADD_APPLICATION_TYPE}`;
export const APPLICATIONS_REMOVE_APPLICATION_TYPE = `${APPLICATIONS_NAMESPACE}/${REMOVE_APPLICATION_TYPE}`;
export const APPLICATIONS_ADD_APPLICATION_STATE = `${APPLICATIONS_NAMESPACE}/${ADD_APPLICATION_STATE}`;
export const APPLICATIONS_REMOVE_APPLICATION_STATE = `${APPLICATIONS_NAMESPACE}/${REMOVE_APPLICATION_STATE}`;

export const APPLICATIONS_ADD_TAG = `${APPLICATIONS_NAMESPACE}/${ADD_TAG}`;
export const APPLICATIONS_REMOVE_TAG = `${APPLICATIONS_NAMESPACE}/${REMOVE_TAG}`;
export const APPLICATIONS_ADD_INTEGRATION_TAG = `${APPLICATIONS_NAMESPACE}/${ADD_INTEGRATION_TAG}`;
export const APPLICATIONS_REMOVE_INTEGRATION_TAG = `${APPLICATIONS_NAMESPACE}/${REMOVE_INTEGRATION_TAG}`;
export const APPLICATIONS_REMOVE_ALL_INTEGRATION_TAGS = `${APPLICATIONS_NAMESPACE}/${REMOVE_ALL_INTEGRATION_TAGS}`;
export const APPLICATIONS_ADD_INTEGRATION = `${APPLICATIONS_NAMESPACE}/${ADD_INTEGRATION}`;
export const APPLICATIONS_SAVE_INTEGRATION = `${APPLICATIONS_NAMESPACE}/${SAVE_INTEGRATION}`;
export const APPLICATIONS_REMOVE_INTEGRATION = `${APPLICATIONS_NAMESPACE}/${REMOVE_INTEGRATION}`;
export const APPLICATIONS_SET_CURRENT_INTEGRATION = `${APPLICATIONS_NAMESPACE}/${SET_CURRENT_INTEGRATION}`;
export const APPLICATIONS_CLEAR_CURRENT_INTEGRATION = `${APPLICATIONS_NAMESPACE}/${CLEAR_CURRENT_INTEGRATION}`;
export const APPLICATIONS_UPDATE_CURRENT_INTEGRATION = `${APPLICATIONS_NAMESPACE}/${UPDATE_CURRENT_INTEGRATION}`;

export const APPLICATIONS_SET_QUESTION_ANSWER = `${APPLICATIONS_NAMESPACE}/${SET_QUESTION_ANSWER}`;
export const APPLICATIONS_UPDATE_PORTFOLIO_FIELD = `${APPLICATIONS_NAMESPACE}/${UPDATE_PORTFOLIO_FIELD}`;
export const APPLICATIONS_UPDATE_TECH_SCORES = `${APPLICATIONS_NAMESPACE}/${UPDATE_TECH_SCORES}`;
export const APPLICATIONS_UPDATE_BUSINESS_SCORES = `${APPLICATIONS_NAMESPACE}/${UPDATE_BUSINESS_SCORES}`;

export const APPLICATIONS_SET_TSM_DATA = `${APPLICATIONS_NAMESPACE}/${SET_TSM_DATA}`;
export const APPLICATIONS_SET_APQC_DATA = `${APPLICATIONS_NAMESPACE}/${SET_APQC_DATA}`;
export const APPLICATIONS_SET_FRAMEWORK_ITEMS = `${APPLICATIONS_NAMESPACE}/${SET_FRAMEWORK_ITEMS}`;

export const APPLICATIONS_ADD_APPLICATION_IT_SERVICE = `${APPLICATIONS_NAMESPACE}/${ADD_APPLICATION_IT_SERVICE}`;
export const APPLICATIONS_SAVE_APPLICATION_IT_SERVICE = `${APPLICATIONS_NAMESPACE}/${SAVE_APPLICATION_IT_SERVICE}`;
export const APPLICATIONS_REMOVE_APPLICATION_IT_SERVICE = `${APPLICATIONS_NAMESPACE}/${REMOVE_APPLICATION_IT_SERVICE}`;
export const APPLICATIONS_SET_CURRENT_APPLICATION_IT_SERVICE = `${APPLICATIONS_NAMESPACE}/${SET_CURRENT_APPLICATION_IT_SERVICE}`;
export const APPLICATIONS_CLEAR_CURRENT_APPLICATION_IT_SERVICE = `${APPLICATIONS_NAMESPACE}/${CLEAR_CURRENT_APPLICATION_IT_SERVICE}`;
export const APPLICATIONS_UPDATE_CURRENT_APPLICATION_IT_SERVICE = `${APPLICATIONS_NAMESPACE}/${UPDATE_CURRENT_APPLICATION_IT_SERVICE}`;

export const APPLICATIONS_SET_MANUFACTURER = `${APPLICATIONS_NAMESPACE}/${SET_MANUFACTURER}`;
export const APPLICATIONS_SET_VENDOR = `${APPLICATIONS_NAMESPACE}/${SET_VENDOR}`;

export const APPLICATIONS_SET_CURRENT_APPLICATION_CAPABILITY = `${APPLICATIONS_NAMESPACE}/${SET_CURRENT_APPLICATION_CAPABILITY}`;
export const APPLICATIONS_CLEAR_CURRENT_APPLICATION_CAPABILITY = `${APPLICATIONS_NAMESPACE}/${CLEAR_CURRENT_APPLICATION_CAPABILITY}`; 
export const APPLICATIONS_SAVE_APPLICATION_CAPABILITY = `${APPLICATIONS_NAMESPACE}/${SAVE_APPLICATION_CAPABILITY}`; 
export const APPLICATIONS_REMOVE_APPLICATION_CAPABILITY = `${APPLICATIONS_NAMESPACE}/${REMOVE_APPLICATION_CAPABILITY}`; 
export const APPLICATIONS_CLEAR_APPLICATION_CAPABILITIES = `${APPLICATIONS_NAMESPACE}/${CLEAR_APPLICATION_CAPABILITIES}`;

// namespaced it services
export const IT_SERVICES_NAMESPACE = 'itservices';
export const IT_SERVICES_GET_ALL_REQUEST = `${IT_SERVICES_NAMESPACE}/${GET_ALL_REQUEST}`;
export const IT_SERVICES_GET_ALL_FAILURE = `${IT_SERVICES_NAMESPACE}/${GET_ALL_FAILURE}`;
export const IT_SERVICES_GET_ALL_SUCCESS = `${IT_SERVICES_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const IT_SERVICES_GET_BY_ID_REQUEST = `${IT_SERVICES_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const IT_SERVICES_GET_BY_ID_FAILURE = `${IT_SERVICES_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const IT_SERVICES_GET_BY_ID_SUCCESS = `${IT_SERVICES_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const IT_SERVICES_CLEAR_ITEMS = `${IT_SERVICES_NAMESPACE}/${CLEAR_ITEMS}`;
export const IT_SERVICES_CLEAR_CURRENT_ITEM = `${IT_SERVICES_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const IT_SERVICES_UPDATE_CURRENT_ITEM = `${IT_SERVICES_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;

export const IT_SERVICES_ADD_CONTACT = `${IT_SERVICES_NAMESPACE}/${ADD_CONTACT}`;
export const IT_SERVICES_REMOVE_CONTACT = `${IT_SERVICES_NAMESPACE}/${REMOVE_CONTACT}`;
export const IT_SERVICES_ADD_PROJECT = `${IT_SERVICES_NAMESPACE}/${ADD_PROJECT}`;
export const IT_SERVICES_REMOVE_PROJECT = `${IT_SERVICES_NAMESPACE}/${REMOVE_PROJECT}`;
export const IT_SERVICES_ADD_AREA = `${IT_SERVICES_NAMESPACE}/${ADD_AREA}`;
export const IT_SERVICES_REMOVE_AREA = `${IT_SERVICES_NAMESPACE}/${REMOVE_AREA}`;

export const IT_SERVICES_ADD_CLASSIFICATION = `${IT_SERVICES_NAMESPACE}/${ADD_CLASSIFICATION}`;
export const IT_SERVICES_REMOVE_CLASSIFICATION = `${IT_SERVICES_NAMESPACE}/${REMOVE_CLASSIFICATION}`;
export const IT_SERVICES_ADD_COMPUTING_TYPE = `${IT_SERVICES_NAMESPACE}/${ADD_COMPUTING_TYPE}`;
export const IT_SERVICES_REMOVE_COMPUTING_TYPE = `${IT_SERVICES_NAMESPACE}/${REMOVE_COMPUTING_TYPE}`;
export const IT_SERVICES_ADD_PLATFORM = `${IT_SERVICES_NAMESPACE}/${ADD_PLATFORM}`;
export const IT_SERVICES_REMOVE_PLATFORM = `${IT_SERVICES_NAMESPACE}/${REMOVE_PLATFORM}`;

export const IT_SERVICES_ADD_APPLICATION_STATE = `${IT_SERVICES_NAMESPACE}/${ADD_APPLICATION_STATE}`;
export const IT_SERVICES_REMOVE_APPLICATION_STATE = `${IT_SERVICES_NAMESPACE}/${REMOVE_APPLICATION_STATE}`;

export const IT_SERVICES_ADD_APPLICATION_IT_SERVICE = `${IT_SERVICES_NAMESPACE}/${ADD_APPLICATION_IT_SERVICE}`;
export const IT_SERVICES_SAVE_APPLICATION_IT_SERVICE = `${IT_SERVICES_NAMESPACE}/${SAVE_APPLICATION_IT_SERVICE}`;
export const IT_SERVICES_REMOVE_APPLICATION_IT_SERVICE = `${IT_SERVICES_NAMESPACE}/${REMOVE_APPLICATION_IT_SERVICE}`;
export const IT_SERVICES_SET_CURRENT_APPLICATION_IT_SERVICE = `${IT_SERVICES_NAMESPACE}/${SET_CURRENT_APPLICATION_IT_SERVICE}`;
export const IT_SERVICES_CLEAR_CURRENT_APPLICATION_IT_SERVICE = `${IT_SERVICES_NAMESPACE}/${CLEAR_CURRENT_APPLICATION_IT_SERVICE}`;
export const IT_SERVICES_UPDATE_CURRENT_APPLICATION_IT_SERVICE = `${IT_SERVICES_NAMESPACE}/${UPDATE_CURRENT_APPLICATION_IT_SERVICE}`;

export const IT_SERVICES_ADD_IT_SERVICE_IT_SERVICE = `${IT_SERVICES_NAMESPACE}/${ADD_IT_SERVICE_IT_SERVICE}`;
export const IT_SERVICES_SAVE_IT_SERVICE_IT_SERVICE = `${IT_SERVICES_NAMESPACE}/${SAVE_IT_SERVICE_IT_SERVICE}`;
export const IT_SERVICES_REMOVE_IT_SERVICE_IT_SERVICE = `${IT_SERVICES_NAMESPACE}/${REMOVE_IT_SERVICE_IT_SERVICE}`;
export const IT_SERVICES_SAVE_USES_IT_SERVICE = `${IT_SERVICES_NAMESPACE}/${SAVE_USES_IT_SERVICE}`;
export const IT_SERVICES_REMOVE_USES_IT_SERVICE = `${IT_SERVICES_NAMESPACE}/${REMOVE_USES_IT_SERVICE}`;

export const IT_SERVICES_SET_CURRENT_IT_SERVICE_IT_SERVICE = `${IT_SERVICES_NAMESPACE}/${SET_CURRENT_IT_SERVICE_IT_SERVICE}`;
export const IT_SERVICES_CLEAR_CURRENT_IT_SERVICE_IT_SERVICE = `${IT_SERVICES_NAMESPACE}/${CLEAR_CURRENT_IT_SERVICE_IT_SERVICE}`;
export const IT_SERVICES_UPDATE_CURRENT_IT_SERVICE_IT_SERVICE = `${IT_SERVICES_NAMESPACE}/${UPDATE_CURRENT_IT_SERVICE_IT_SERVICE}`;

export const IT_SERVICES_SET_TSM_DATA = `${IT_SERVICES_NAMESPACE}/${SET_TSM_DATA}`;
export const IT_SERVICES_SET_FRAMEWORK_ITEMS = `${IT_SERVICES_NAMESPACE}/${SET_FRAMEWORK_ITEMS}`;

export const IT_SERVICES_SET_MANUFACTURER = `${IT_SERVICES_NAMESPACE}/${SET_MANUFACTURER}`;
export const IT_SERVICES_SET_VENDOR = `${IT_SERVICES_NAMESPACE}/${SET_VENDOR}`;


//contacts
export const ADD_APPLICATION_CONTACT = 'addApplicationContact';
export const REMOVE_APPLICATION_CONTACT = 'removeApplicationContact';
export const ADD_IT_SERVICE_CONTACT = 'addITServiceContact';
export const REMOVE_IT_SERVICE_CONTACT = 'removeITServiceContact';
export const ADD_SUPPLIER_CONTACT = 'addSupplierContact';
export const REMOVE_SUPPLIER_CONTACT = 'removeSupplierContact';

// namespaced contact
export const CONTACTS_NAMESPACE = 'contacts';
export const CONTACTS_GET_ALL_REQUEST = `${CONTACTS_NAMESPACE}/${GET_ALL_REQUEST}`;
export const CONTACTS_GET_ALL_FAILURE = `${CONTACTS_NAMESPACE}/${GET_ALL_FAILURE}`;
export const CONTACTS_GET_ALL_SUCCESS = `${CONTACTS_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const CONTACTS_GET_BY_ID_REQUEST = `${CONTACTS_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const CONTACTS_GET_BY_ID_FAILURE = `${CONTACTS_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const CONTACTS_GET_BY_ID_SUCCESS = `${CONTACTS_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const CONTACTS_CLEAR_ITEMS = `${CONTACTS_NAMESPACE}/${CLEAR_ITEMS}`;
export const CONTACTS_CLEAR_CURRENT_ITEM = `${CONTACTS_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const CONTACTS_UPDATE_CURRENT_ITEM = `${CONTACTS_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;
export const CONTACTS_UPDATE_LIST_ITEM = `${CONTACTS_NAMESPACE}/${UPDATE_LIST_ITEM}`;

export const CONTACTS_ADD_AREA = `${CONTACTS_NAMESPACE}/${ADD_AREA}`;
export const CONTACTS_REMOVE_AREA = `${CONTACTS_NAMESPACE}/${REMOVE_AREA}`;
export const CONTACTS_ADD_APPLICATION_CONTACT = `${CONTACTS_NAMESPACE}/${ADD_APPLICATION_CONTACT}`;
export const CONTACTS_REMOVE_APPLICATION_CONTACT = `${CONTACTS_NAMESPACE}/${REMOVE_APPLICATION_CONTACT}`;
export const CONTACTS_ADD_IT_SERVICE_CONTACT = `${CONTACTS_NAMESPACE}/${ADD_IT_SERVICE_CONTACT}`;
export const CONTACTS_REMOVE_IT_SERVICE_CONTACT = `${CONTACTS_NAMESPACE}/${REMOVE_IT_SERVICE_CONTACT}`;
export const CONTACTS_ADD_SUPPLIER_CONTACT = `${CONTACTS_NAMESPACE}/${ADD_SUPPLIER_CONTACT}`;
export const CONTACTS_REMOVE_SUPPLIER_CONTACT = `${CONTACTS_NAMESPACE}/${REMOVE_SUPPLIER_CONTACT}`;

//projects
export const ADD_APPLICATION_PROJECT = 'addApplicationProject';
export const REMOVE_APPLICATION_PROJECT = 'removeApplicationProject';
export const ADD_IT_SERVICE_PROJECT = 'addITServiceProject';
export const REMOVE_IT_SERVICE_PROJECT = 'removeITServiceProject';

export const ADD_PROGRAM_PROJECT = 'addProgramProject';
export const REMOVE_PROGRAM_PROJECT = 'removeProgramProject';

export const ADD_PROJECT_PORTFOLIO = 'addProjectPortfolio';
export const REMOVE_PROJECT_PORTFOLIO = 'removeProjectPortfolio';

export const ADD_PROJECT_DRIVER = 'addProjectDriver';
export const REMOVE_PROJECT_DRIVER = 'removeProjectDriver';

export const SAVE_VALUE_DRIVER = 'addValueDrivber';
export const REMOVE_VALUE_DRIVER = 'removeValueDriver';

export const ADD_NOTE = 'addNote';
export const SAVE_NOTE = 'saveNote';
export const REMOVE_NOTE = 'removeNote';
export const SET_CURRENT_NOTE = 'setCurrentNote';
export const CLEAR_CURRENT_NOTE = 'clearCurrentNote';
export const UPDATE_CURRENT_NOTE = 'updateCurrentNote';

export const ADD_NOTE_COMMENT = 'addNoteComment';

export const SET_SELECTED_BOARD_STATUS = 'setSelectedBoardStatus';

// namespaced projects
export const PROJECTS_NAMESPACE = 'projects';

export const PROJECTS_VIEW_BOARD = `${PROJECTS_NAMESPACE}/${VIEW_BOARD}`;
export const PROJECTS_VIEW_GRID = `${PROJECTS_NAMESPACE}/${VIEW_GRID}`;
export const PROJECTS_SET_SELECTED_BOARD_STATUS = `${PROJECTS_NAMESPACE}/${SET_SELECTED_BOARD_STATUS}`;

export const PROJECTS_ADD_FILTER_PRIORITY = `${PROJECTS_NAMESPACE}/${ADD_FILTER_PRIORITY}`;
export const PROJECTS_REMOVE_FILTER_PRIORITY = `${PROJECTS_NAMESPACE}/${REMOVE_FILTER_PRIORITY}`;
export const PROJECTS_ADD_FILTER_TYPE = `${PROJECTS_NAMESPACE}/${ADD_FILTER_TYPE}`;
export const PROJECTS_REMOVE_FILTER_TYPE = `${PROJECTS_NAMESPACE}/${REMOVE_FILTER_TYPE}`;
export const PROJECTS_ADD_FILTER_PROGRAM = `${PROJECTS_NAMESPACE}/${ADD_FILTER_PROGRAM}`;
export const PROJECTS_REMOVE_FILTER_PROGRAM = `${PROJECTS_NAMESPACE}/${REMOVE_FILTER_PROGRAM}`;
export const PROJECTS_ADD_FILTER_SPONSOR = `${PROJECTS_NAMESPACE}/${ADD_FILTER_SPONSOR}`;
export const PROJECTS_REMOVE_FILTER_SPONSOR = `${PROJECTS_NAMESPACE}/${REMOVE_FILTER_SPONSOR}`;
export const PROJECTS_ADD_FILTER_INITIATED_BY = `${PROJECTS_NAMESPACE}/${ADD_FILTER_INITIATED_BY}`;
export const PROJECTS_REMOVE_FILTER_INITIATED_BY = `${PROJECTS_NAMESPACE}/${REMOVE_FILTER_INITIATED_BY}`;

export const PROJECTS_GET_ALL_REQUEST = `${PROJECTS_NAMESPACE}/${GET_ALL_REQUEST}`;
export const PROJECTS_GET_ALL_FAILURE = `${PROJECTS_NAMESPACE}/${GET_ALL_FAILURE}`;
export const PROJECTS_GET_ALL_SUCCESS = `${PROJECTS_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const PROJECTS_GET_BY_ID_REQUEST = `${PROJECTS_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const PROJECTS_GET_BY_ID_FAILURE = `${PROJECTS_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const PROJECTS_GET_BY_ID_SUCCESS = `${PROJECTS_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const PROJECTS_CLEAR_ITEMS = `${PROJECTS_NAMESPACE}/${CLEAR_ITEMS}`;
export const PROJECTS_CLEAR_CURRENT_ITEM = `${PROJECTS_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const PROJECTS_UPDATE_CURRENT_ITEM = `${PROJECTS_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;
export const PROJECTS_UPDATE_LIST_ITEM = `${PROJECTS_NAMESPACE}/${UPDATE_LIST_ITEM}`;

export const PROJECTS_UPDATE_FILTER = `${PROJECTS_NAMESPACE}/${UPDATE_FILTER}`;

export const PROJECTS_ADD_APPLICATION_PROJECT = `${PROJECTS_NAMESPACE}/${ADD_APPLICATION_PROJECT}`;
export const PROJECTS_REMOVE_APPLICATION_PROJECT = `${PROJECTS_NAMESPACE}/${REMOVE_APPLICATION_PROJECT}`;

export const PROJECTS_ADD_IT_SERVICE_PROJECT = `${PROJECTS_NAMESPACE}/${ADD_IT_SERVICE_PROJECT}`;
export const PROJECTS_REMOVE_IT_SERVICE_PROJECT = `${PROJECTS_NAMESPACE}/${REMOVE_IT_SERVICE_PROJECT}`;

export const PROJECTS_ADD_PROGRAM_PROJECT = `${PROJECTS_NAMESPACE}/${ADD_PROGRAM_PROJECT}`;
export const PROJECTS_REMOVE_PROGRAM_PROJECT = `${PROJECTS_NAMESPACE}/${REMOVE_PROGRAM_PROJECT}`;

export const PROJECTS_ADD_PROJECT_PORTFOLIO = `${PROJECTS_NAMESPACE}/${ADD_PROJECT_PORTFOLIO}`;
export const PROJECTS_REMOVE_PROJECT_PORTFOLIO = `${PROJECTS_NAMESPACE}/${REMOVE_PROJECT_PORTFOLIO}`;

export const PROJECTS_ADD_PROJECT_DRIVER = `${PROJECTS_NAMESPACE}/${ADD_PROJECT_DRIVER}`;
export const PROJECTS_REMOVE_PROJECT_DRIVER = `${PROJECTS_NAMESPACE}/${REMOVE_PROJECT_DRIVER}`;

export const PROJECTS_SAVE_VALUE_DRIVER = `${PROJECTS_NAMESPACE}/${SAVE_VALUE_DRIVER}`;
export const PROJECTS_REMOVE_VALUE_DRIVER = `${PROJECTS_NAMESPACE}/${REMOVE_VALUE_DRIVER}`;

export const PROJECTS_ADD_AREA = `${PROJECTS_NAMESPACE}/${ADD_AREA}`;
export const PROJECTS_REMOVE_AREA = `${PROJECTS_NAMESPACE}/${REMOVE_AREA}`;

export const PROJECTS_ADD_NOTE = `${PROJECTS_NAMESPACE}/${ADD_NOTE}`;
export const PROJECTS_SAVE_NOTE = `${PROJECTS_NAMESPACE}/${SAVE_NOTE}`;
export const PROJECTS_REMOVE_NOTE = `${PROJECTS_NAMESPACE}/${REMOVE_NOTE}`;
export const PROJECTS_SET_CURRENT_NOTE = `${PROJECTS_NAMESPACE}/${SET_CURRENT_NOTE}`;
export const PROJECTS_CLEAR_CURRENT_NOTE = `${PROJECTS_NAMESPACE}/${CLEAR_CURRENT_NOTE}`;
export const PROJECTS_UPDATE_CURRENT_NOTE = `${PROJECTS_NAMESPACE}/${UPDATE_CURRENT_NOTE}`;
export const PROJECTS_ADD_NOTE_COMMENT = `${PROJECTS_NAMESPACE}/${ADD_NOTE_COMMENT}`;


//programs
export const ADD_PROGRAM_DRIVER = 'addProgramDriver';
export const REMOVE_PROGRAM_DRIVER = 'removeProgramDriver';

export const ADD_PROGRAM_IDEA = 'addProgramIdea';
export const REMOVE_PROGRAM_IDEA = 'removeProgramIdea';

// namespaced programs
export const PROGRAMS_NAMESPACE = 'programs';
export const PROGRAMS_GET_ALL_REQUEST = `${PROGRAMS_NAMESPACE}/${GET_ALL_REQUEST}`;
export const PROGRAMS_GET_ALL_FAILURE = `${PROGRAMS_NAMESPACE}/${GET_ALL_FAILURE}`;
export const PROGRAMS_GET_ALL_SUCCESS = `${PROGRAMS_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const PROGRAMS_GET_BY_ID_REQUEST = `${PROGRAMS_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const PROGRAMS_GET_BY_ID_FAILURE = `${PROGRAMS_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const PROGRAMS_GET_BY_ID_SUCCESS = `${PROGRAMS_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const PROGRAMS_CLEAR_ITEMS = `${PROGRAMS_NAMESPACE}/${CLEAR_ITEMS}`;
export const PROGRAMS_CLEAR_CURRENT_ITEM = `${PROGRAMS_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const PROGRAMS_UPDATE_CURRENT_ITEM = `${PROGRAMS_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;
export const PROGRAMS_UPDATE_LIST_ITEM = `${PROGRAMS_NAMESPACE}/${UPDATE_LIST_ITEM}`;


export const PROGRAMS_ADD_PROGRAM_PROJECT = `${PROGRAMS_NAMESPACE}/${ADD_PROGRAM_PROJECT}`;
export const PROGRAMS_REMOVE_PROGRAM_PROJECT = `${PROGRAMS_NAMESPACE}/${REMOVE_PROGRAM_PROJECT}`;

export const PROGRAMS_ADD_PROGRAM_IDEA = `${PROGRAMS_NAMESPACE}/${ADD_PROGRAM_IDEA}`;
export const PROGRAMS_REMOVE_PROGRAM_IDEA = `${PROGRAMS_NAMESPACE}/${REMOVE_PROGRAM_IDEA}`;

export const PROGRAMS_ADD_PROGRAM_DRIVER = `${PROGRAMS_NAMESPACE}/${ADD_PROGRAM_DRIVER}`;
export const PROGRAMS_REMOVE_PROGRAM_DRIVER = `${PROGRAMS_NAMESPACE}/${REMOVE_PROGRAM_DRIVER}`;

export const PROGRAMS_SET_EXPANDED_ITEMS = `${PROGRAMS_NAMESPACE}/${SET_EXPANDED_ITEMS}`;

// tags
export const ADD_APPLICATION_TAG = 'addApplicationTag';
export const REMOVE_APPLICATION_TAG = 'removeApplicationTag';

// namespaced tags
export const TAGS_NAMESPACE = 'tags';
export const TAGS_GET_ALL_REQUEST = `${TAGS_NAMESPACE}/${GET_ALL_REQUEST}`;
export const TAGS_GET_ALL_FAILURE = `${TAGS_NAMESPACE}/${GET_ALL_FAILURE}`;
export const TAGS_GET_ALL_SUCCESS = `${TAGS_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const TAGS_GET_BY_ID_REQUEST = `${TAGS_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const TAGS_GET_BY_ID_FAILURE = `${TAGS_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const TAGS_GET_BY_ID_SUCCESS = `${TAGS_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const TAGS_CLEAR_ITEMS = `${TAGS_NAMESPACE}/${CLEAR_ITEMS}`;
export const TAGS_CLEAR_CURRENT_ITEM = `${TAGS_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const TAGS_UPDATE_CURRENT_ITEM = `${TAGS_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;

export const TAGS_ADD_APPLICATION_TAG = `${TAGS_NAMESPACE}/${ADD_APPLICATION_TAG}`;
export const TAGS_REMOVE_APPLICATION_TAG = `${TAGS_NAMESPACE}/${REMOVE_APPLICATION_TAG}`;


// areas
export const ADD_APPLICATION_AREA = 'addApplicationArea';
export const REMOVE_APPLICATION_AREA = 'removeApplicationArea';

// namespaced areas
export const AREAS_NAMESPACE = 'areas';
export const AREAS_GET_ALL_REQUEST = `${AREAS_NAMESPACE}/${GET_ALL_REQUEST}`;
export const AREAS_GET_ALL_FAILURE = `${AREAS_NAMESPACE}/${GET_ALL_FAILURE}`;
export const AREAS_GET_ALL_SUCCESS = `${AREAS_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const AREAS_GET_BY_ID_REQUEST = `${AREAS_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const AREAS_GET_BY_ID_FAILURE = `${AREAS_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const AREAS_GET_BY_ID_SUCCESS = `${AREAS_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const AREAS_CLEAR_ITEMS = `${AREAS_NAMESPACE}/${CLEAR_ITEMS}`;
export const AREAS_CLEAR_CURRENT_ITEM = `${AREAS_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const AREAS_UPDATE_CURRENT_ITEM = `${AREAS_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;

export const AREAS_ADD_APPLICATION_AREA = `${AREAS_NAMESPACE}/${ADD_APPLICATION_AREA}`;
export const AREAS_REMOVE_APPLICATION_AREA = `${AREAS_NAMESPACE}/${REMOVE_APPLICATION_AREA}`;

// suppliers

// namespaced suppliers
export const SUPPLIERS_NAMESPACE = 'suppliers';
export const SUPPLIERS_GET_ALL_REQUEST = `${SUPPLIERS_NAMESPACE}/${GET_ALL_REQUEST}`;
export const SUPPLIERS_GET_ALL_FAILURE = `${SUPPLIERS_NAMESPACE}/${GET_ALL_FAILURE}`;
export const SUPPLIERS_GET_ALL_SUCCESS = `${SUPPLIERS_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const SUPPLIERS_GET_BY_ID_REQUEST = `${SUPPLIERS_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const SUPPLIERS_GET_BY_ID_FAILURE = `${SUPPLIERS_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const SUPPLIERS_GET_BY_ID_SUCCESS = `${SUPPLIERS_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const SUPPLIERS_CLEAR_ITEMS = `${SUPPLIERS_NAMESPACE}/${CLEAR_ITEMS}`;
export const SUPPLIERS_CLEAR_CURRENT_ITEM = `${SUPPLIERS_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const SUPPLIERS_UPDATE_CURRENT_ITEM = `${SUPPLIERS_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;
export const SUPPLIERS_UPDATE_ADDRESS = `${SUPPLIERS_NAMESPACE}/${UPDATE_ADDRESS}`;

// links

// namespaced links
export const LINKS_NAMESPACE = 'links';
export const LINKS_GET_ALL_REQUEST = `${LINKS_NAMESPACE}/${GET_ALL_REQUEST}`;
export const LINKS_GET_ALL_FAILURE = `${LINKS_NAMESPACE}/${GET_ALL_FAILURE}`;
export const LINKS_GET_ALL_SUCCESS = `${LINKS_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const LINKS_GET_BY_ID_REQUEST = `${LINKS_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const LINKS_GET_BY_ID_FAILURE = `${LINKS_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const LINKS_GET_BY_ID_SUCCESS = `${LINKS_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const LINKS_CLEAR_ITEMS = `${LINKS_NAMESPACE}/${CLEAR_ITEMS}`;
export const LINKS_CLEAR_CURRENT_ITEM = `${LINKS_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const LINKS_UPDATE_CURRENT_ITEM = `${LINKS_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;



// portfolios
export const SET_SELECTED_PORTFOLIO = 'updateSelectedPortfolioId';
export const UPDATE_PORTFOLIO_NAME = 'updatePortfolioName';
export const UPDATE_PORTFOLIO_MANAGER = 'updatePortfolioManager';
export const GET_FILTERS_REQUEST = 'getFiltersRequest';
export const GET_FILTERS_FAILURE = 'getFiltersFailure';
export const GET_FILTERS_SUCCESS = 'getFiltersSuccess';
export const SET_PORTFOLIO_ROLE = 'setPortfolioRole';

// namespaced portfolios
export const PORTFOLIOS_NAMESPACE = 'portfolios';
export const PORTFOLIOS_GET_ALL_REQUEST = `${PORTFOLIOS_NAMESPACE}/${GET_ALL_REQUEST}`;
export const PORTFOLIOS_GET_ALL_FAILURE = `${PORTFOLIOS_NAMESPACE}/${GET_ALL_FAILURE}`;
export const PORTFOLIOS_GET_ALL_SUCCESS = `${PORTFOLIOS_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const PORTFOLIOS_GET_SUMMARY_REQUEST = `${PORTFOLIOS_NAMESPACE}/${GET_SUMMARY_REQUEST}`;
export const PORTFOLIOS_GET_SUMMARY_FAILURE = `${PORTFOLIOS_NAMESPACE}/${GET_SUMMARY_FAILURE}`;
export const PORTFOLIOS_GET_SUMMARY_SUCCESS = `${PORTFOLIOS_NAMESPACE}/${GET_SUMMARY_SUCCESS}`;
export const PORTFOLIOS_GET_BY_ID_REQUEST = `${PORTFOLIOS_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const PORTFOLIOS_GET_BY_ID_FAILURE = `${PORTFOLIOS_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const PORTFOLIOS_GET_BY_ID_SUCCESS = `${PORTFOLIOS_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const PORTFOLIOS_CLEAR_ITEMS = `${PORTFOLIOS_NAMESPACE}/${CLEAR_ITEMS}`;
export const PORTFOLIOS_CLEAR_CURRENT_ITEM = `${PORTFOLIOS_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const PORTFOLIOS_GET_FILTERS_REQUEST = `${PORTFOLIOS_NAMESPACE}/${GET_FILTERS_REQUEST}`;
export const PORTFOLIOS_GET_FILTERS_FAILURE = `${PORTFOLIOS_NAMESPACE}/${GET_FILTERS_FAILURE}`;
export const PORTFOLIOS_GET_FILTERS_SUCCESS = `${PORTFOLIOS_NAMESPACE}/${GET_FILTERS_SUCCESS}`;
export const PORTFOLIOS_SET_SELECTED_PORTFOLIO = `${PORTFOLIOS_NAMESPACE}/${SET_SELECTED_PORTFOLIO}`;
export const PORTFOLIOS_UPDATE_PORTFOLIO_NAME = `${PORTFOLIOS_NAMESPACE}/${UPDATE_PORTFOLIO_NAME}`;
export const PORTFOLIOS_UPDATE_PORTFOLIO_MANAGER = `${PORTFOLIOS_NAMESPACE}/${UPDATE_PORTFOLIO_MANAGER}`;
export const PORTFOLIOS_UPDATE_BUSINESS_SCORES = `${PORTFOLIOS_NAMESPACE}/${UPDATE_BUSINESS_SCORES}`;
export const PORTFOLIOS_SET_PORTFOLIO_ROLE = `${PORTFOLIOS_NAMESPACE}/${SET_PORTFOLIO_ROLE}`;

// namespaced tech questions
export const TECH_QUESTIONS_NAMESPACE = 'techquestions';
export const TECH_QUESTIONS_GET_ALL_REQUEST = `${TECH_QUESTIONS_NAMESPACE}/${GET_ALL_REQUEST}`;
export const TECH_QUESTIONS_GET_ALL_FAILURE = `${TECH_QUESTIONS_NAMESPACE}/${GET_ALL_FAILURE}`;
export const TECH_QUESTIONS_GET_ALL_SUCCESS = `${TECH_QUESTIONS_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const TECH_QUESTIONS_CLEAR_ITEMS = `${TECH_QUESTIONS_NAMESPACE}/${CLEAR_ITEMS}`;

// business questions
export const GET_BY_PORTFOLIO_ID_REQUEST = 'getByPortfolioIdRequest';
export const GET_BY_PORTFOLIO_ID_FAILURE = 'getByPortfolioIdFailure';
export const GET_BY_PORTFOLIO_ID_SUCCESS = 'getByPortfolioIdSuccess';

// namespaced business questions
export const BUSINESS_QUESTIONS_NAMESPACE = 'businessquestions';
export const BUSINESS_QUESTIONS_GET_ALL_REQUEST = `${BUSINESS_QUESTIONS_NAMESPACE}/${GET_ALL_REQUEST}`;
export const BUSINESS_QUESTIONS_GET_ALL_FAILURE = `${BUSINESS_QUESTIONS_NAMESPACE}/${GET_ALL_FAILURE}`;
export const BUSINESS_QUESTIONS_GET_ALL_SUCCESS = `${BUSINESS_QUESTIONS_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const BUSINESS_GET_BY_PORTFOLIO_ID_REQUEST = `${BUSINESS_QUESTIONS_NAMESPACE}/${GET_BY_PORTFOLIO_ID_REQUEST}`;
export const BUSINESS_GET_BY_PORTFOLIO_ID_FAILURE = `${BUSINESS_QUESTIONS_NAMESPACE}/${GET_BY_PORTFOLIO_ID_FAILURE}`;
export const BUSINESS_GET_BY_PORTFOLIO_ID_SUCCESS = `${BUSINESS_QUESTIONS_NAMESPACE}/${GET_BY_PORTFOLIO_ID_SUCCESS}`;
export const BUSINESS_QUESTIONS_CLEAR_ITEMS = `${BUSINESS_QUESTIONS_NAMESPACE}/${CLEAR_ITEMS}`;
export const BUSINESS_QUESTIONS_UPDATE_LIST_ITEM = `${BUSINESS_QUESTIONS_NAMESPACE}/${UPDATE_LIST_ITEM}`;


// namespaced users
export const SET_CURRENT_ACCOUNT_ROLE = 'setCurrentAccountRole';
export const UPDATE_CURRENT_ACCOUNT_ROLE = 'updateCurrentAccountRole';
export const GET_PREFERENCES_REQUEST = 'getPreferencesRequest';
export const GET_PREFERENCES_SUCCESS = 'getPreferencesSuccess';
export const GET_PREFERENCES_FAILURE = 'getPreferencesFailure';

export const USERS_NAMESPACE = 'users';
export const USERS_GET_BY_ID_REQUEST = `${USERS_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const USERS_GET_BY_ID_FAILURE = `${USERS_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const USERS_GET_BY_ID_SUCCESS = `${USERS_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const USERS_GET_PREFERENCES_REQUEST = `${USERS_NAMESPACE}/${GET_PREFERENCES_REQUEST}`;
export const USERS_GET_PREFERENCES_FAILURE = `${USERS_NAMESPACE}/${GET_PREFERENCES_FAILURE}`;
export const USERS_GET_PREFERENCES_SUCCESS = `${USERS_NAMESPACE}/${GET_PREFERENCES_SUCCESS}`;
export const USERS_CLEAR_CURRENT_ITEM = `${USERS_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const USERS_UPDATE_CURRENT_ITEM = `${USERS_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;
export const USERS_SET_CURRENT_ACCOUNT_ROLE = `${USERS_NAMESPACE}/${SET_CURRENT_ACCOUNT_ROLE}`;
export const USERS_UPDATE_CURRENT_ACCOUNT_ROLE = `${USERS_NAMESPACE}/${UPDATE_CURRENT_ACCOUNT_ROLE}`;
export const USERS_SET_PORTFOLIO_ROLE = `${USERS_NAMESPACE}/${SET_PORTFOLIO_ROLE}`;

// namespaced ref app states
export const REF_APPLICATION_STATES_NAMESPACE = 'refapplicationstates';
export const REF_APPLICATION_STATES_GET_ALL_REQUEST = `${REF_APPLICATION_STATES_NAMESPACE}/${GET_ALL_REQUEST}`;
export const REF_APPLICATION_STATES_GET_ALL_FAILURE = `${REF_APPLICATION_STATES_NAMESPACE}/${GET_ALL_FAILURE}`;
export const REF_APPLICATION_STATES_GET_ALL_SUCCESS = `${REF_APPLICATION_STATES_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const REF_APPLICATION_STATES_GET_BY_ID_REQUEST = `${REF_APPLICATION_STATES_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const REF_APPLICATION_STATES_GET_BY_ID_FAILURE = `${REF_APPLICATION_STATES_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const REF_APPLICATION_STATES_GET_BY_ID_SUCCESS = `${REF_APPLICATION_STATES_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const REF_APPLICATION_STATES_CLEAR_ITEMS = `${REF_APPLICATION_STATES_NAMESPACE}/${CLEAR_ITEMS}`;
export const REF_APPLICATION_STATES_CLEAR_CURRENT_ITEM = `${REF_APPLICATION_STATES_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const REF_APPLICATION_STATES_UPDATE_CURRENT_ITEM = `${REF_APPLICATION_STATES_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;

// namespaced ref app types
export const REF_APPLICATION_TYPES_NAMESPACE = 'refapplicationtypes';
export const REF_APPLICATION_TYPES_GET_ALL_REQUEST = `${REF_APPLICATION_TYPES_NAMESPACE}/${GET_ALL_REQUEST}`;
export const REF_APPLICATION_TYPES_GET_ALL_FAILURE = `${REF_APPLICATION_TYPES_NAMESPACE}/${GET_ALL_FAILURE}`;
export const REF_APPLICATION_TYPES_GET_ALL_SUCCESS = `${REF_APPLICATION_TYPES_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const REF_APPLICATION_TYPES_GET_BY_ID_REQUEST = `${REF_APPLICATION_TYPES_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const REF_APPLICATION_TYPES_GET_BY_ID_FAILURE = `${REF_APPLICATION_TYPES_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const REF_APPLICATION_TYPES_GET_BY_ID_SUCCESS = `${REF_APPLICATION_TYPES_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const REF_APPLICATION_TYPES_CLEAR_ITEMS = `${REF_APPLICATION_TYPES_NAMESPACE}/${CLEAR_ITEMS}`;
export const REF_APPLICATION_TYPES_CLEAR_CURRENT_ITEM = `${REF_APPLICATION_TYPES_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const REF_APPLICATION_TYPES_UPDATE_CURRENT_ITEM = `${REF_APPLICATION_TYPES_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;

// namespaced ref tech types
export const REF_TECHNOLOGY_TYPES_NAMESPACE = 'reftechnologytypes';
export const REF_TECHNOLOGY_TYPES_GET_ALL_REQUEST = `${REF_TECHNOLOGY_TYPES_NAMESPACE}/${GET_ALL_REQUEST}`;
export const REF_TECHNOLOGY_TYPES_GET_ALL_FAILURE = `${REF_TECHNOLOGY_TYPES_NAMESPACE}/${GET_ALL_FAILURE}`;
export const REF_TECHNOLOGY_TYPES_GET_ALL_SUCCESS = `${REF_TECHNOLOGY_TYPES_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const REF_TECHNOLOGY_TYPES_GET_BY_ID_REQUEST = `${REF_TECHNOLOGY_TYPES_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const REF_TECHNOLOGY_TYPES_GET_BY_ID_FAILURE = `${REF_TECHNOLOGY_TYPES_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const REF_TECHNOLOGY_TYPES_GET_BY_ID_SUCCESS = `${REF_TECHNOLOGY_TYPES_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const REF_TECHNOLOGY_TYPES_CLEAR_ITEMS = `${REF_TECHNOLOGY_TYPES_NAMESPACE}/${CLEAR_ITEMS}`;
export const REF_TECHNOLOGY_TYPES_CLEAR_CURRENT_ITEM = `${REF_TECHNOLOGY_TYPES_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const REF_TECHNOLOGY_TYPES_UPDATE_CURRENT_ITEM = `${REF_TECHNOLOGY_TYPES_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;

// namespaced ref integration types
export const REF_INTEGRATION_TYPES_NAMESPACE = 'refintegrationtypes';
export const REF_INTEGRATION_TYPES_GET_ALL_REQUEST = `${REF_INTEGRATION_TYPES_NAMESPACE}/${GET_ALL_REQUEST}`;
export const REF_INTEGRATION_TYPES_GET_ALL_FAILURE = `${REF_INTEGRATION_TYPES_NAMESPACE}/${GET_ALL_FAILURE}`;
export const REF_INTEGRATION_TYPES_GET_ALL_SUCCESS = `${REF_INTEGRATION_TYPES_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const REF_INTEGRATION_TYPES_GET_BY_ID_REQUEST = `${REF_INTEGRATION_TYPES_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const REF_INTEGRATION_TYPES_GET_BY_ID_FAILURE = `${REF_INTEGRATION_TYPES_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const REF_INTEGRATION_TYPES_GET_BY_ID_SUCCESS = `${REF_INTEGRATION_TYPES_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const REF_INTEGRATION_TYPES_CLEAR_ITEMS = `${REF_INTEGRATION_TYPES_NAMESPACE}/${CLEAR_ITEMS}`;
export const REF_INTEGRATION_TYPES_CLEAR_CURRENT_ITEM = `${REF_INTEGRATION_TYPES_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const REF_INTEGRATION_TYPES_UPDATE_CURRENT_ITEM = `${REF_INTEGRATION_TYPES_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;

// namespaced ref classifications
export const REF_CLASSIFICATIONS_NAMESPACE = 'refclassifications';
export const REF_CLASSIFICATIONS_GET_ALL_REQUEST = `${REF_CLASSIFICATIONS_NAMESPACE}/${GET_ALL_REQUEST}`;
export const REF_CLASSIFICATIONS_GET_ALL_FAILURE = `${REF_CLASSIFICATIONS_NAMESPACE}/${GET_ALL_FAILURE}`;
export const REF_CLASSIFICATIONS_GET_ALL_SUCCESS = `${REF_CLASSIFICATIONS_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const REF_CLASSIFICATIONS_GET_BY_ID_REQUEST = `${REF_CLASSIFICATIONS_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const REF_CLASSIFICATIONS_GET_BY_ID_FAILURE = `${REF_CLASSIFICATIONS_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const REF_CLASSIFICATIONS_GET_BY_ID_SUCCESS = `${REF_CLASSIFICATIONS_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const REF_CLASSIFICATIONS_CLEAR_ITEMS = `${REF_CLASSIFICATIONS_NAMESPACE}/${CLEAR_ITEMS}`;
export const REF_CLASSIFICATIONS_CLEAR_CURRENT_ITEM = `${REF_CLASSIFICATIONS_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const REF_CLASSIFICATIONS_UPDATE_CURRENT_ITEM = `${REF_CLASSIFICATIONS_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;

// namespaced ref computing types
export const REF_COMPUTING_TYPES_NAMESPACE = 'refcomputingtypes';
export const REF_COMPUTING_TYPES_GET_ALL_REQUEST = `${REF_COMPUTING_TYPES_NAMESPACE}/${GET_ALL_REQUEST}`;
export const REF_COMPUTING_TYPES_GET_ALL_FAILURE = `${REF_COMPUTING_TYPES_NAMESPACE}/${GET_ALL_FAILURE}`;
export const REF_COMPUTING_TYPES_GET_ALL_SUCCESS = `${REF_COMPUTING_TYPES_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const REF_COMPUTING_TYPES_GET_BY_ID_REQUEST = `${REF_COMPUTING_TYPES_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const REF_COMPUTING_TYPES_GET_BY_ID_FAILURE = `${REF_COMPUTING_TYPES_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const REF_COMPUTING_TYPES_GET_BY_ID_SUCCESS = `${REF_COMPUTING_TYPES_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const REF_COMPUTING_TYPES_CLEAR_ITEMS = `${REF_COMPUTING_TYPES_NAMESPACE}/${CLEAR_ITEMS}`;
export const REF_COMPUTING_TYPES_CLEAR_CURRENT_ITEM = `${REF_COMPUTING_TYPES_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const REF_COMPUTING_TYPES_UPDATE_CURRENT_ITEM = `${REF_COMPUTING_TYPES_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;

// namespaced ref platforms
export const REF_PLATFORMS_NAMESPACE = 'refplatforms';
export const REF_PLATFORMS_GET_ALL_REQUEST = `${REF_PLATFORMS_NAMESPACE}/${GET_ALL_REQUEST}`;
export const REF_PLATFORMS_GET_ALL_FAILURE = `${REF_PLATFORMS_NAMESPACE}/${GET_ALL_FAILURE}`;
export const REF_PLATFORMS_GET_ALL_SUCCESS = `${REF_PLATFORMS_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const REF_PLATFORMS_GET_BY_ID_REQUEST = `${REF_PLATFORMS_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const REF_PLATFORMS_GET_BY_ID_FAILURE = `${REF_PLATFORMS_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const REF_PLATFORMS_GET_BY_ID_SUCCESS = `${REF_PLATFORMS_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const REF_PLATFORMS_CLEAR_ITEMS = `${REF_PLATFORMS_NAMESPACE}/${CLEAR_ITEMS}`;
export const REF_PLATFORMS_CLEAR_CURRENT_ITEM = `${REF_PLATFORMS_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const REF_PLATFORMS_UPDATE_CURRENT_ITEM = `${REF_PLATFORMS_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;

// namespaced ref classifications
export const CAPABILITY_SETS_NAMESPACE = 'capabilitysets';
export const CAPABILITY_SETS_GET_ALL_REQUEST = `${CAPABILITY_SETS_NAMESPACE}/${GET_ALL_REQUEST}`;
export const CAPABILITY_SETS_GET_ALL_FAILURE = `${CAPABILITY_SETS_NAMESPACE}/${GET_ALL_FAILURE}`;
export const CAPABILITY_SETS_GET_ALL_SUCCESS = `${CAPABILITY_SETS_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const CAPABILITY_SETS_GET_BY_ID_REQUEST = `${CAPABILITY_SETS_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const CAPABILITY_SETS_GET_BY_ID_FAILURE = `${CAPABILITY_SETS_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const CAPABILITY_SETS_GET_BY_ID_SUCCESS = `${CAPABILITY_SETS_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const CAPABILITY_SETS_CLEAR_ITEMS = `${CAPABILITY_SETS_NAMESPACE}/${CLEAR_ITEMS}`;
export const CAPABILITY_SETS_CLEAR_CURRENT_ITEM = `${CAPABILITY_SETS_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const CAPABILITY_SETS_UPDATE_CURRENT_ITEM = `${CAPABILITY_SETS_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;

// ideas

// namespaced ideas
export const IDEAS_NAMESPACE = 'ideas';
export const IDEAS_VIEW_BOARD = `${IDEAS_NAMESPACE}/${VIEW_BOARD}`;
export const IDEAS_VIEW_GRID = `${IDEAS_NAMESPACE}/${VIEW_GRID}`;
export const IDEAS_SET_FILTER = `${IDEAS_NAMESPACE}/${SET_FILTER}`;
export const IDEAS_ADD_FILTER_PRIORITY = `${IDEAS_NAMESPACE}/${ADD_FILTER_PRIORITY}`;
export const IDEAS_REMOVE_FILTER_PRIORITY = `${IDEAS_NAMESPACE}/${REMOVE_FILTER_PRIORITY}`;
export const IDEAS_ADD_FILTER_PORTFOLIO = `${IDEAS_NAMESPACE}/${ADD_FILTER_PORTFOLIO}`;
export const IDEAS_REMOVE_FILTER_PORTFOLIO = `${IDEAS_NAMESPACE}/${REMOVE_FILTER_PORTFOLIO}`;
export const IDEAS_ADD_FILTER_OWNER = `${IDEAS_NAMESPACE}/${ADD_FILTER_OWNER}`;
export const IDEAS_REMOVE_FILTER_OWNER = `${IDEAS_NAMESPACE}/${REMOVE_FILTER_OWNER}`;
export const IDEAS_ADD_FILTER_INITIATED_BY = `${IDEAS_NAMESPACE}/${ADD_FILTER_INITIATED_BY}`;
export const IDEAS_REMOVE_FILTER_INITIATED_BY = `${IDEAS_NAMESPACE}/${REMOVE_FILTER_INITIATED_BY}`;

// analytics
export const SET_SELECTED_DASHBOARD = 'updateSelectedDashboardId';
export const CLEAR_NAMESPACES = 'clearNamespaces';
export const CLEAR_CURRENT_NAMESPACE = 'clearCurrentNamespace';
export const GET_NAMESPACES_REQUEST = 'getNamespaceRequest';
export const GET_NAMESPACES_SUCCESS = 'getNamespaceSuccess';
export const GET_NAMESPACES_FAILURE = 'getNamespaceFailure';
export const GET_NAMESPACE_BY_ID_REQUEST = 'getNamespaceByIdRequest';
export const GET_NAMESPACE_BY_ID_SUCCESS = 'getNamespaceByIdSuccess';
export const GET_NAMESPACE_BY_ID_FAILURE = 'getNamespaceByIdFailure';

// namespaced analytics
export const ANALYTICS_NAMESPACE = 'analytics';
export const ANALYTICS_GET_ALL_REQUEST = `${ANALYTICS_NAMESPACE}/${GET_ALL_REQUEST}`;
export const ANALYTICS_GET_ALL_FAILURE = `${ANALYTICS_NAMESPACE}/${GET_ALL_FAILURE}`;
export const ANALYTICS_GET_ALL_SUCCESS = `${ANALYTICS_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const ANALYTICS_CLEAR_ITEMS = `${ANALYTICS_NAMESPACE}/${CLEAR_ITEMS}`;
export const ANALYTICS_SET_SELECTED_DASHBOARD = `${ANALYTICS_NAMESPACE}/${SET_SELECTED_DASHBOARD}`;
export const ANALYTICS_CLEAR_NAMESPACES = `${ANALYTICS_NAMESPACE}/${CLEAR_NAMESPACES}`;
export const ANALYTICS_CLEAR_CURRENT_NAMESPACE = `${ANALYTICS_NAMESPACE}/${CLEAR_CURRENT_NAMESPACE}`;

// namespaced ref project sizes
export const REF_PROJECT_SIZES_NAMESPACE = 'refprojectsizes';
export const REF_PROJECT_SIZES_GET_ALL_REQUEST = `${REF_PROJECT_SIZES_NAMESPACE}/${GET_ALL_REQUEST}`;
export const REF_PROJECT_SIZES_GET_ALL_FAILURE = `${REF_PROJECT_SIZES_NAMESPACE}/${GET_ALL_FAILURE}`;
export const REF_PROJECT_SIZES_GET_ALL_SUCCESS = `${REF_PROJECT_SIZES_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const REF_PROJECT_SIZES_GET_BY_ID_REQUEST = `${REF_PROJECT_SIZES_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const REF_PROJECT_SIZES_GET_BY_ID_FAILURE = `${REF_PROJECT_SIZES_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const REF_PROJECT_SIZES_GET_BY_ID_SUCCESS = `${REF_PROJECT_SIZES_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const REF_PROJECT_SIZES_CLEAR_ITEMS = `${REF_PROJECT_SIZES_NAMESPACE}/${CLEAR_ITEMS}`;
export const REF_PROJECT_SIZES_CLEAR_CURRENT_ITEM = `${REF_PROJECT_SIZES_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const REF_PROJECT_SIZES_UPDATE_CURRENT_ITEM = `${REF_PROJECT_SIZES_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;

// namespaced ref project types
export const REF_PROJECT_TYPES_NAMESPACE = 'refprojecttypes';
export const REF_PROJECT_TYPES_GET_ALL_REQUEST = `${REF_PROJECT_TYPES_NAMESPACE}/${GET_ALL_REQUEST}`;
export const REF_PROJECT_TYPES_GET_ALL_FAILURE = `${REF_PROJECT_TYPES_NAMESPACE}/${GET_ALL_FAILURE}`;
export const REF_PROJECT_TYPES_GET_ALL_SUCCESS = `${REF_PROJECT_TYPES_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const REF_PROJECT_TYPES_GET_BY_ID_REQUEST = `${REF_PROJECT_TYPES_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const REF_PROJECT_TYPES_GET_BY_ID_FAILURE = `${REF_PROJECT_TYPES_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const REF_PROJECT_TYPES_GET_BY_ID_SUCCESS = `${REF_PROJECT_TYPES_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const REF_PROJECT_TYPES_CLEAR_ITEMS = `${REF_PROJECT_TYPES_NAMESPACE}/${CLEAR_ITEMS}`;
export const REF_PROJECT_TYPES_CLEAR_CURRENT_ITEM = `${REF_PROJECT_TYPES_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const REF_PROJECT_TYPES_UPDATE_CURRENT_ITEM = `${REF_PROJECT_TYPES_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;

// namespaced ref project stages
export const REF_PROJECT_STAGES_NAMESPACE = 'refprojectstages';
export const REF_PROJECT_STAGES_GET_ALL_REQUEST = `${REF_PROJECT_STAGES_NAMESPACE}/${GET_ALL_REQUEST}`;
export const REF_PROJECT_STAGES_GET_ALL_FAILURE = `${REF_PROJECT_STAGES_NAMESPACE}/${GET_ALL_FAILURE}`;
export const REF_PROJECT_STAGES_GET_ALL_SUCCESS = `${REF_PROJECT_STAGES_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const REF_PROJECT_STAGES_GET_BY_ID_REQUEST = `${REF_PROJECT_STAGES_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const REF_PROJECT_STAGES_GET_BY_ID_FAILURE = `${REF_PROJECT_STAGES_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const REF_PROJECT_STAGES_GET_BY_ID_SUCCESS = `${REF_PROJECT_STAGES_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const REF_PROJECT_STAGES_CLEAR_ITEMS = `${REF_PROJECT_STAGES_NAMESPACE}/${CLEAR_ITEMS}`;
export const REF_PROJECT_STAGES_CLEAR_CURRENT_ITEM = `${REF_PROJECT_STAGES_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const REF_PROJECT_STAGES_UPDATE_CURRENT_ITEM = `${REF_PROJECT_STAGES_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;

// namespaced ref project statuses
export const REF_PROJECT_STATUSES_NAMESPACE = 'refprojectstatuses';
export const REF_PROJECT_STATUSES_GET_ALL_REQUEST = `${REF_PROJECT_STATUSES_NAMESPACE}/${GET_ALL_REQUEST}`;
export const REF_PROJECT_STATUSES_GET_ALL_FAILURE = `${REF_PROJECT_STATUSES_NAMESPACE}/${GET_ALL_FAILURE}`;
export const REF_PROJECT_STATUSES_GET_ALL_SUCCESS = `${REF_PROJECT_STATUSES_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const REF_PROJECT_STATUSES_GET_BY_ID_REQUEST = `${REF_PROJECT_STATUSES_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const REF_PROJECT_STATUSES_GET_BY_ID_FAILURE = `${REF_PROJECT_STATUSES_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const REF_PROJECT_STATUSES_GET_BY_ID_SUCCESS = `${REF_PROJECT_STATUSES_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const REF_PROJECT_STATUSES_CLEAR_ITEMS = `${REF_PROJECT_STATUSES_NAMESPACE}/${CLEAR_ITEMS}`;
export const REF_PROJECT_STATUSES_CLEAR_CURRENT_ITEM = `${REF_PROJECT_STATUSES_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const REF_PROJECT_STATUSES_UPDATE_CURRENT_ITEM = `${REF_PROJECT_STATUSES_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;

// namespaced ref drivers
export const REF_DRIVERS_NAMESPACE = 'refdrivers';
export const REF_DRIVERS_GET_ALL_REQUEST = `${REF_DRIVERS_NAMESPACE}/${GET_ALL_REQUEST}`;
export const REF_DRIVERS_GET_ALL_FAILURE = `${REF_DRIVERS_NAMESPACE}/${GET_ALL_FAILURE}`;
export const REF_DRIVERS_GET_ALL_SUCCESS = `${REF_DRIVERS_NAMESPACE}/${GET_ALL_SUCCESS}`;
export const REF_DRIVERS_GET_BY_ID_REQUEST = `${REF_DRIVERS_NAMESPACE}/${GET_BY_ID_REQUEST}`;
export const REF_DRIVERS_GET_BY_ID_FAILURE = `${REF_DRIVERS_NAMESPACE}/${GET_BY_ID_FAILURE}`;
export const REF_DRIVERS_GET_BY_ID_SUCCESS = `${REF_DRIVERS_NAMESPACE}/${GET_BY_ID_SUCCESS}`;
export const REF_DRIVERS_CLEAR_ITEMS = `${REF_DRIVERS_NAMESPACE}/${CLEAR_ITEMS}`;
export const REF_DRIVERS_CLEAR_CURRENT_ITEM = `${REF_DRIVERS_NAMESPACE}/${CLEAR_CURRENT_ITEM}`;
export const REF_DRIVERS_UPDATE_CURRENT_ITEM = `${REF_DRIVERS_NAMESPACE}/${UPDATE_CURRENT_ITEM}`;